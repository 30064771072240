/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useRef, useState } from 'react';
import { FaExternalLinkAlt, FaPause, FaPlay } from 'react-icons/fa';
import ReactPlayer from 'react-player/lazy';

import { ImageProps, LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { NavBreadcrumbsProps } from '../NavBreadcrumbs';
import { StyledPageHero, StyledPageHeroNavBreadcrumbs } from './styles';

interface IShareListItem {
  link: LinkProps;
  type: IconName;
}
const ShareListItem = ({ link, type }: IShareListItem): ReactElement => (
  <li>
    <a
      href={link.href}
      target="_blank"
      rel="noopener noreferrer"
      title={link.label || type}
    >
      <FontAwesomeIcon icon={['fab', type]} />
    </a>
  </li>
);

export interface PageHeroProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  titleOnly?: boolean;
  fullWidthBackground?: boolean;
  epiTitle: EpiProps;
  epiSubtitle?: EpiProps;
  epiDescription?: EpiProps;
  ribbonColor?: String;
  ribbonLabel?: string;
  image?: ImageProps;
  video?: {
    source: string;
  };
  facebookUrl?: LinkProps;
  twitterUrl?: LinkProps;
  linkedInUrl?: LinkProps;
  projectStatus?:
    | 'under_construction'
    | 'planned'
    | 'completed'
    | 'local_initiative'
    | 'consultation'
    | 'other';
  projectIcon?: string;
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
}

const PROJECT_STATUSES = {
  under_construction: {
    icon: '/images/icon_construction--white.svg',
    color: '#054E66',
    label: 'Under construction',
  },
  planned: {
    icon: '/images/icon_planning--white.svg',
    color: '#D14210',
    label: 'In planning',
  },
  completed: {
    icon: '/images/icon_complete--white.svg',
    color: '#008072',
    label: 'Complete',
  },
  local_initiative: {
    icon: '/images/icon_magnify--white.svg',
    color: '#008072',
    label: 'Local roads initiative',
  },
  consultation: {
    icon: '/images/icon_comments--white.svg',
    color: '#D14210',
    label: 'Community consultation',
  },
  other: {
    icon: '',
    color: '#D14210',
    label: 'Community consultation',
  },
};

interface IPageHeroCard {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  epiTitle: EpiProps;
  epiSubtitle?: EpiProps;
  projectStatus?:
    | 'under_construction'
    | 'planned'
    | 'completed'
    | 'local_initiative'
    | 'consultation'
    | 'other';
  ribbonColor?: String;
  ribbonLabel?: string;
  projectIcon?: string;
  epiDescription?: EpiProps;
  facebookUrl?: LinkProps;
  twitterUrl?: LinkProps;
  linkedInUrl?: LinkProps;
}

const PageHeroCard = ({
  breadcrumbs,
  epiTitle,
  epiSubtitle,
  projectStatus,
  epiDescription,
  ribbonLabel,
  ribbonColor = 'blue',
  projectIcon,
  facebookUrl,
  twitterUrl,
  linkedInUrl,
}: IPageHeroCard): ReactElement => {
  return (
    <div className="page-hero__content">
      {breadcrumbs && <StyledPageHeroNavBreadcrumbs items={breadcrumbs} />}
      <EpiProperty
        className="page-hero__title"
        name={epiTitle.name}
        value={epiTitle.value}
        isEditMode={epiTitle.isEditMode}
        outerTag="h1"
      />
      {epiSubtitle && (
        <EpiProperty
          name={epiSubtitle.name}
          className="subtitle"
          value={epiSubtitle.value}
          isEditMode={epiSubtitle.isEditMode}
          outerTag="p"
        />
      )}

      {ribbonLabel && (
        <div className={`ribbon ${ribbonColor}`}>
          {!!projectStatus && (
            <>
              {projectStatus == 'other' ? (
                <img src={projectIcon} alt={ribbonLabel} />
              ) : (
                <img
                  src={PROJECT_STATUSES[projectStatus].icon}
                  alt={PROJECT_STATUSES[projectStatus].label}
                />
              )}
            </>
          )}
          <span>{ribbonLabel}</span>
        </div>
      )}
      {epiDescription?.value && (
        <div className="page-hero__content-description">
          <EpiProperty
            name={epiDescription.name}
            value={epiDescription.value}
            isEditMode={epiDescription.isEditMode}
            isHTMLContent={epiDescription.isHTMLContent}
            outerTag="p"
          />
        </div>
      )}

      {(facebookUrl || twitterUrl || linkedInUrl) && (
        <div className="page-hero__social-links">
          <span>Share this project</span>
          <ul>
            {facebookUrl && (
              <ShareListItem link={facebookUrl} type="facebook" />
            )}
            {twitterUrl && <ShareListItem link={twitterUrl} type="twitter" />}
            {linkedInUrl && (
              <ShareListItem link={linkedInUrl} type="linkedin" />
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

const PageHero = ({
  breadcrumbs,
  titleOnly = null,
  fullWidthBackground = true,
  epiTitle,
  epiSubtitle,
  projectStatus,
  epiDescription,
  ribbonLabel,
  ribbonColor,
  projectIcon,
  image,
  video,
  facebookUrl,
  twitterUrl,
  linkedInUrl,
  caption = '',
  attributionTo = '',
  attributionLink = '',
}: PageHeroProps): ReactElement => {
  const videoIsYoutube = video?.source
    ? video?.source.indexOf('.mp4') > 0
      ? false
      : true
    : null;

  const player = useRef(null);

  const [playing, setPlaying] = useState(false);

  const titleOnlyMode =
    !!titleOnly ||
    (!image &&
      !epiSubtitle?.value &&
      !breadcrumbs &&
      !projectIcon &&
      !epiDescription?.value &&
      !facebookUrl &&
      !twitterUrl &&
      !linkedInUrl);

  return (
    <StyledPageHero className={titleOnlyMode ? 'title-only' : ''}>
      <div className="page-hero__container--outer padded">
        <div className="page-hero__container">
          {titleOnlyMode ? (
            <Container>
              <EpiProperty
                className="page-hero__title"
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="h1"
              />
              {breadcrumbs && (
                <StyledPageHeroNavBreadcrumbs items={breadcrumbs} />
              )}
            </Container>
          ) : (
            <PageHeroCard
              breadcrumbs={breadcrumbs}
              epiTitle={epiTitle}
              epiSubtitle={epiSubtitle}
              projectStatus={projectStatus}
              epiDescription={epiDescription}
              ribbonColor={ribbonColor}
              ribbonLabel={ribbonLabel}
              projectIcon={projectIcon}
              facebookUrl={facebookUrl}
              twitterUrl={twitterUrl}
              linkedInUrl={linkedInUrl}
            />
          )}
        </div>
      </div>
      {!titleOnlyMode && (
        <div
          className="page-hero__caption"
        >
          <div
            className="page-hero__container--outer padded"
          >
            <p>
              {!!caption && caption}
              {!!attributionTo && (
                <>
                  {!!caption && !!attributionTo && <br />}
                  <span>{!!attributionLink ? (<a href={attributionLink} target="_blank">{attributionTo}</a>) : attributionTo}</span>
                </>
              )}
            </p>
              <div className='page-hero__controls'>
                {!!video?.source &&
                  <>
                    <button
                      title={playing ? 'Pause Video' : 'Start Video'}
                      onClick={() => {
                        setPlaying(!playing);
                      }}
                    >
                      {playing ? <FaPause></FaPause> : <FaPlay></FaPlay>}
                    </button>
                  </>
                }
                {!!image &&
                  <>
                    <a
                      title="Click to view image"
                      href={image.src}
                      target="_blank"
                    >
                      <FaExternalLinkAlt />
                    </a>
                  </>
                }
              </div>
          </div>
        </div>
      )}
      {!!image?.src && !titleOnlyMode && (
        <div
          className={`page-hero__image ${
            fullWidthBackground ? 'full-width-background' : ''
          }`}
        >
          <img src={image.src} alt={image.alt} />
        </div>
      )}
      {!!video?.source && (
        <div
          className={`page-hero__image ${
            fullWidthBackground ? 'full-width-background' : ''
          }`}
          onClick={() => {
            setPlaying(!playing);
          }}
        >
          <ReactPlayer
            ref={player}
            url={video.source}
            controls={false}
            config={
              videoIsYoutube && {
                youtube: {
                  playerVars: {
                    modestbranding: 0,
                    rel: 0,
                  },
                },
              }
            }
            loop
            volume={0}
            muted
            playsinline
            playing={playing}
            className={`${
              videoIsYoutube ? 'is-youtube' : 'is-videofile'
            } page-hero__player`}
            width="100%"
            height="100%"
            onReady={() => {
              // Only autoplay if screen width above 768 pixels
              if (window.innerWidth >= 768) setPlaying(true);
            }}
            // onStart={() => {
            //   setVideoReady(true);
            // }}
          />
        </div>
      )}
    </StyledPageHero>
  );
};

export default PageHero;
