import styled, { css } from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

interface MobileBreakpointProp {
  mobilebreakpoint?: 'sm' | 'md' | 'lg';
}

interface StyledSidebarProps extends MobileBreakpointProp {
  isOpen: boolean;
}

export const StyledSidebar = styled.aside<StyledSidebarProps>`
  position: absolute;
  background-color: ${theme.colors.sidebarBg};
  width: 83%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  transition: transform 1000ms cubic-bezier(0.32, 0.01, 0.15, 0.99);
  padding-right: ${props => (props.isOpen ? '0' : '1.5rem')};
  transform: translate(
    ${props => (props.isOpen ? '0%' : 'calc(-100% - 0.75rem)')},
    0
  );
  p {
    padding-right: 1.5rem;
  }

  &:before {
    content: '';
    background-color: ${theme.colors.sidebarBg};
    width: calc(25% - 0.75rem);
    position: absolute;
    top: 0;
    right: 100%;
    width: 1.5rem;
    height: 100%;
  }

  ${props => breakpoint.greaterThan(props.mobilebreakpoint)`
    padding-right: 0;
    top: auto;
    left: auto;
    height: auto;
    transition: none;
    transform: none;
    position: relative;
    width: 100%;
  `}

  ${breakpoint.greaterThan('container')`
    &:before{
      width: calc(((100vw - 1512px) / 2));
    }
  `};
`;

export const StyledSpanner = styled.div<MobileBreakpointProp>`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.sidebarBg};
  width: 100%;

  ${props => breakpoint.greaterThan(props.mobilebreakpoint)`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% + 24px);
  `};
`;

export const StyledInner = styled.div<MobileBreakpointProp>`
  padding-top: 50px;
  width: 100%;
  h4 {
    margin-top: 0;
    margin-bottom: 0.25em;
    padding-left: 24px;
    font-size: 0.875em;
    font-weight: 400;
    span {
      a {
        color: #000;
      }
    }

    ${props => breakpoint.greaterThan(props.mobilebreakpoint)`
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
      margin-bottom: 0.25em;
      padding-left: 0px;
      span {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 400px;
        padding-right: 25px;
      }
    `};

    ${breakpoint.greaterThan('container')`
      span {
        max-width: 400px;
      }
    `};
  }
  li {
    margin: 0;
    padding: 0;
  }
  ul {
    margin-bottom: 60px;
  }
  li {
    display: block;
    padding: 8px 0px 8px 24px;
    ${props => breakpoint.greaterThan(props.mobilebreakpoint)`
      display: flex;
      padding: 8px 0px 8px 0;
      justify-content: flex-end;
    `};
    &:hover {
      background-color: ${theme.colors.linkHover};
      a {
        color: #fff;
      }
    }
    a {
      display: block;
      color: inherit;
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: flex-start;
      font-size: 0.875em;
      font-weight: 300;
    }
    ${breakpoint.greaterThan('container')`
      a {
        max-width: 400px;
      }
    `};
  }
  ${props => breakpoint.greaterThan(props.mobilebreakpoint)`
    padding-left: 24px;
  `};
`;

export const StyledToggleButton = styled.button<StyledSidebarProps>`
  display: none;
  ${props => breakpoint.lessThan(props.mobilebreakpoint)`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    cursor: pointer;
    ${
      props.isOpen
        ? css`
            right: 0;
            padding: 1em;
            background-color: transparent;
            color: #054e66;
            font-size: 0.625em;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid #054e66;
              border-radius: 50%;
              width: 2em;
              height: 2em;
            }
            svg.fa-times {
              width: 1.2em;
              height: 1.2em;
            }
            &:hover {
              color: ${theme.colors.linkHover};
              > span {
                border-color: ${theme.colors.linkHover};
              }
            }
          `
        : css`
            left: 100%;
            background-color: #054e66;
            color: #fff;
            width: 2em;
            height: 2em;
            margin-top: 0.625em;
          `
    }
  `};
`;
