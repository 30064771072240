import styled from 'styled-components';
import { hideVisually } from 'polished';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledSearchForm = styled.form`
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;

  button {
    padding: 0.75rem;
    background: none;
    border: solid 1px transparent;
    font-size: 1.25rem;
    line-height: 0;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    -webkit-appearance: none;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `}

    &:hover {
      color: ${theme.colors.linkHover};
    }

    span {
      ${hideVisually}
    }
  }

  input {
    padding: 0.5rem 0.75rem;
    background: none;
    border: 1px solid transparent;
    font-size: 0.75em;
    font-weight: 400;
    width: 100%;
    &::placeholder {
      line-height: normal !important;
    }
    &:focus::placeholder {
      transition: 125ms ease-out;
      color: transparent;
    }

    ${breakpoint.greaterThan('md')`
      font-size: 1.125em;
    `}
    ${breakpoint.greaterThan('lg')`
      padding: 0.75rem 1rem;
    `}
  }

  &.theme-dark {
    background-color: ${theme.colors.headerBg};
    border: 1px solid ${theme.colors.headerSearchBorder};
    button {
      color: ${theme.colors.headerSearchText};
      &:hover {
        color: ${theme.colors.linkHover};
      }
    }
    input {
      color: ${theme.colors.headerSearchText};
      &::placeholder {
        color: ${theme.colors.headerSearchPlaceholderText};
      }
    }
  }
  &.theme-light {
    background-color: ${theme.colors.textLight};
    border: 1px solid ${theme.colors.headerSearchBorder};
    button {
      color: ${theme.colors.textDark};
      &:hover {
        color: ${theme.colors.linkHover};
      }
    }
    input {
      color: #2d2f32;
      &::placeholder {
        color: ${theme.colors.textDark};
      }
    }
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  padding: 0.75rem;
`;
