import React, { ReactElement } from 'react';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import {
  StyledCTABlock,
  StyledCTABlockContent,
  StyledCTABlockCallout,
} from './StyledCTABlock';
import { BaseBlockProps } from '../../../types'

export interface CTABlockProps extends BaseBlockProps {
  contentText: EpiProps;
  contentTitle: EpiProps;
  calloutTitle?: EpiProps;
  calloutText?: EpiProps;
  // hasTopMargin?: boolean;
  calloutLink?: {
    href: string;
    target?: string;
    title: string;
  };
}

const CTABlock = ({
  contentText,
  contentTitle,
  calloutTitle,
  calloutText,
  calloutLink,
  anchorId
}: // hasTopMargin = true,
CTABlockProps): ReactElement => {
  return (
    <StyledCTABlock id={anchorId}>
      <Container>
        <div className="cta-block__inner">
          <StyledCTABlockContent>
            <EpiProperty
              name={contentTitle.name}
              value={contentTitle.value}
              isEditMode={contentTitle.isEditMode}
              outerTag="h2"
            />
            <EpiProperty
              name={contentText.name}
              value={contentText.value}
              isEditMode={contentText.isEditMode}
              outerTag="p"
            />
          </StyledCTABlockContent>
          {(calloutTitle || calloutText || calloutLink) && (
            <StyledCTABlockCallout>
              {calloutTitle && (
                <EpiProperty
                  name={calloutTitle.name}
                  value={calloutTitle.value}
                  isEditMode={calloutTitle.isEditMode}
                  outerTag="h2"
                />
              )}
              {calloutText && (
                <EpiProperty
                  name={calloutText.name}
                  value={calloutText.value}
                  isEditMode={calloutText.isEditMode}
                  outerTag="p"
                />
              )}
              {calloutLink && (
                <a
                  href={calloutLink.href}
                  className="gtm-track"
                  target={calloutLink.target ? calloutLink.target : '_self'}
                  title={calloutLink.title}
                >
                  {calloutLink.title}
                </a>
              )}
            </StyledCTABlockCallout>
          )}
        </div>
      </Container>
    </StyledCTABlock>
  );
};

export default CTABlock;
