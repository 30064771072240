export function round(value: number, precision: number): number {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const fileSizetoString = (fileSize: number): string => {
  let theString = '';
  if (fileSize > 900) {
    theString = `${round(fileSize / 1024, 2)} KB`;
  }
  if (fileSize > 900000) {
    theString = `${round(fileSize / 1024 / 1024, 2)} MB`;
  }
  if (fileSize > 900000000) {
    theString = `${round(fileSize / 1024 / 1024 / 1024, 2)} GB`;
  }
  return theString;
};

// Takes an int from 0 - 25 and outputs letter at index of alphabet, either uppercase or lowercase
export const intToLetter = (int, uppercase) => {
  const startChar = uppercase ? 'A' : 'a';
  if (int < 26) {
    const code = startChar.charCodeAt(0);
    return String.fromCharCode(code + int);
  }
  return int;
};

// WARNING not truly unique, unique enough for front end react map keys
export const uniqKey = function () {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

// Return current url search params without specified param
export const getUrlOtherSearchParams = (param) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete(param);
  return `?${urlSearchParams.toString()}`;
};

export const isHexColorRegex: RegExp = /^#([0-9a-f]{6}|[0-9a-f]{3})$/i;
export const isRGBaColorRegex: RegExp =
  /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;

//= =========================
// Storybook Control helpers
//= =========================

/**
 * take JS datetime and output date as string, to help with storybook date controls
 * @param time: number
 * @returns string
 */
export const toDateString = (time: number | string): string => {
  const date = new Date(parseInt(`${time}`));
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

// Remove Epi prop keys from storybook controls
export const cleanEpiArgs = (obj) => {
  const tempObj = { ...obj };

  Object.keys(tempObj).forEach((key) => {
    if (key.startsWith('epi')) {
      delete tempObj[key]?.name;
      delete tempObj[key]?.isEditMode;
      delete tempObj[key]?.isHTMLContent;
    }
  });

  return tempObj;
};

// Remove object args from controls, to be used when flattening/unflattening object args
export const removeObjectArgs = (obj) => {
  const argTypes = {};

  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    )
      argTypes[key] = { table: { disable: true } };
  });

  return argTypes;
};

// Rename flattened Api args for readability in stroybook controls
export const renameObjectArgs = (obj) => {
  const argTypes = {};

  Object.keys(obj).forEach((key) => {
    argTypes[key] = {
      name: key.replace('epi', ' ').replace('.value', '').replace('.', ' '),
    };
  });

  return argTypes;
};

// Remove all args from object from storybook controls
export const removeAllArgs = (obj) => {
  const argTypes = {};

  Object.keys(obj).forEach((key) => {
    argTypes[key] = { table: { disable: true } };
  });

  return argTypes;
};
