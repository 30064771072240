import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledNavMobileItem = styled.li`
  display: block;
  margin: 0;
  padding: 0;
  border-bottom: solid 1px ${theme.colors.navMobileBorder};
  line-height: 0;

  &:last-of-type {
    border: none;
  }

  a {
    display: block;
    padding: 1rem 1.5rem 1rem 0.75rem;
    color: ${theme.colors.navLinkColor};
    font-size: 1rem;
    line-height: 1.5;
    font-weight: ${theme.fontWeight.bold};
    border-bottom: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${theme.colors.linkHover};
      border-bottom: none;
    }
  }

  &.nav-mobile__item--active a {
    color: ${theme.colors.linkActive};
  }
`;

export interface NavMobileItemProps {
  active?: boolean;
  href: string;
  target?: string;
  title: string;
}

function NavMobileItem({
  active,
  href,
  target = '_self',
  title,
}: NavMobileItemProps): ReactElement {
  return (
    <StyledNavMobileItem
      className={
        active
          ? 'nav-mobile__item nav-mobile__item--active'
          : 'nav-mobile__item'
      }
    >
      <a href={href} target={target} title={title}>
        {title}
      </a>
    </StyledNavMobileItem>
  );
}

export default NavMobileItem;
