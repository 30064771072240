import React, { ReactElement } from 'react';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageContent from '../../atoms/PageContent';
import PageSummary from '../../atoms/PageSummary';
import PageTitle from '../../atoms/PageTitle';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';

type FullWidthPageProps = {
  breadcrumbs?: PageTemplateProps['breadcrumbs'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  padding: boolean;
  epiTitle: EpiProps;
  epiSummary?: EpiProps;
  epiContent: EpiProps;
  epiMainContentArea: EpiProps;
  lastReviewed?: string;
  lastUpdated?: string;
  alert?: PageTemplateProps['alert'];
};

function FullWidthPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  epiSummary,
  epiContent,
  epiMainContentArea,
  padding = true,
  lastReviewed = undefined,
  lastUpdated = undefined,
  alert,
}: FullWidthPageProps): ReactElement {
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      ctaBlock={ctaBlock}
      header={header}
      footer={footer}
      alert={alert}
    >
      <Container padding={padding}>
        <PageTitle>
          <EpiProperty
            name={epiTitle.name}
            value={epiTitle.value}
            isEditMode={epiTitle.isEditMode}
            outerTag="span"
          />
        </PageTitle>
        {epiSummary && (
          <PageSummary>
            <EpiProperty
              name={epiSummary.name}
              value={epiSummary.value}
              isEditMode={epiSummary.isEditMode}
              outerTag="p"
            />
          </PageSummary>
        )}
        <PageContent>
          <EpiProperty
            name={epiContent.name}
            value={epiContent.value}
            isEditMode={epiContent.isEditMode}
            isHTMLContent={epiContent.isHTMLContent}
            outerTag="div"
          />
        </PageContent>
        <EpiProperty
          name={epiMainContentArea.name}
          value={epiMainContentArea.value}
          isEditMode={epiMainContentArea.isEditMode}
          outerTag="div"
        />
        <PageLastReviewed
          lastReviewed={lastReviewed}
          lastUpdated={lastUpdated}
          pageType="page"
        />
      </Container>
    </PageTemplate>
  );
}

export default FullWidthPage;
