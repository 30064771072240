import axios from 'axios';
import { useKeenSlider } from 'keen-slider/react';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Loaderizer from '../../atoms/Loaderizer';
import {
  GroupedProjectListSection,
  GroupedProjectsFlippers,
  GroupedProjectsStyles,
  LoadingStyles,
  ProjectListsItemStyles,
} from './GroupedProjectsStyles';
import { BaseBlockProps } from '../../../types'

interface IProject {
  img: string;
  title: string;
  excerpt: string;
  uri: string;
}

const ProjectListsItem = ({
  project,
  compact,
}: {
  project: IProject;
  compact?: boolean;
}) => (
  <ProjectListsItemStyles className={compact ? 'compact' : ''}>
    <figure>
      <img src={project.img} alt={project.title} />
    </figure>
    <div>
      <h3>{project.title}</h3>
      <p dangerouslySetInnerHTML={{ __html: project.excerpt }} />
    </div>
    <a href={project.uri} title={project.title}>
      <span className="visually-hidden">{project.uri}</span>
    </a>
  </ProjectListsItemStyles>
);

const ProjectDotsControl = ({
  count,
  page,
  setPage,
}: {
  count: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <div className="controls">
      <button
        type="button"
        className="navigation"
        onClick={() => {
          setPage(page > 0 ? page - 1 : count - 1);
        }}
      >
        <span className="visually-hidden">previous</span>
        <FaArrowLeft />
      </button>
      {}{' '}
      {[...Array(count)].map((project, i) => (
        <button
          key={i}
          type="button"
          className={page === i ? 'active' : ''}
          onClick={() => {
            setPage(i);
          }}
        >
          <span className="visually-hidden">page {i + 1}</span>
        </button>
      ))}
      <button
        type="button"
        className="navigation"
        onClick={() => {
          setPage(page < count - 1 ? page + 1 : 0);
        }}
      >
        <span className="visually-hidden">previous</span>
        <FaArrowRight />
      </button>
    </div>
  );
};

const ProjectsSlider = ({
  projectsSet,
  activeSlide,
  setPage,
  compact,
}: {
  projectsSet: { title: string; projects: IProject[] }[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  activeSlide: number;
  compact?: boolean;
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider]: [any, any] = useKeenSlider<HTMLDivElement>({
    duration: 0,
    initial: currentSlide,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  useEffect(() => {
    setPage(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    if (sliderRef.current && !!slider) {
      slider?.moveToSlideRelative(activeSlide);
    }
  }, [activeSlide]);

  return (
    <div className="keen-slider" ref={sliderRef}>
      {projectsSet.map((list, i) => (
        <div
          key={`${list.title}_${i}`}
          className="keen-slider__slide projects__slide"
        >
          <div className="projects__slide--inner">
            <ul>
              {list.projects.map((project, j) => (
                <li key={j}>
                  <ProjectListsItem project={project} compact={compact} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

const GroupedProjectsBlock = (
  {
    title,
    compact,
    apiPath,
    anchorId
  }: {
    title: string;
    compact?: boolean;
    apiPath?: string;
    anchorId?: string
  } = {
    title: null,
    compact: false,
    apiPath: '/api/projects/groupedprojects',
  },
) => {
  const [page, setPage] = useState(0);
  const [isSmall, setIsSmall] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sectionRef = useRef(null);

  const fetchProjects = async () => {
    setIsLoading(true);
    // TODO: Replace this with proper feed to grab latest projects
    // TODO: Update ProjectListsItem to reflect any data format changes
    await axios(apiPath)
      .then(res => res.data)
      .then(data => {
        setIsLoading(false);
        setProjects(data);
        return data;
      })
      .catch(reason => console.error(reason));
  };

  const onResizeHandler = () => {
    if (window.matchMedia('(max-width: 575px)').matches) {
      return setIsSmall(true);
    }
    const componentWidth = parseInt(
      getComputedStyle(sectionRef.current).width,
      10,
    );
    if (componentWidth < 576) {
      return setIsSmall(true);
    }
    return setIsSmall(false);
  };

  useEffect(() => {
    if (mounted === true) {
      return undefined;
    }
    fetchProjects();
    window.addEventListener('resize', onResizeHandler);
    onResizeHandler();
    // mounted
    setMounted(true);
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  return (
    <GroupedProjectsStyles
      id={anchorId}
      ref={sectionRef}
      className={`${isSmall ? 'is-small' : ''}${compact ? 'compact' : ''}`}
    >
      <header>
        <div className="title">
          <h2>{title}</h2>
          <p>{!!projects && projects[page].title}</p>
        </div>
        {!!projects && (
          <ProjectDotsControl
            count={projects.length}
            page={page}
            setPage={setPage}
          />
        )}
      </header>
      <GroupedProjectsFlippers>
        <button
          type="button"
          className="flipper__left"
          onClick={() => {
            setPage(page > 0 ? page - 1 : projects.length - 1);
          }}
        >
          <span className="visually-hidden">Slide to previous group</span>
        </button>
        <button
          type="button"
          className="flipper__right"
          onClick={() => {
            const targetPage = page < projects.length - 1 ? page + 1 : 0;
            setPage(targetPage);
          }}
        >
          <span className="visually-hidden">Slide to next group</span>
        </button>
      </GroupedProjectsFlippers>
      <GroupedProjectListSection>
        <div>
          {isLoading ? (
            <LoadingStyles>
              <Loaderizer />
            </LoadingStyles>
          ) : (
            <div className="projects__lists">
              {!!projects && (
                <ProjectsSlider
                  activeSlide={page}
                  setPage={setPage}
                  projectsSet={projects}
                  compact={compact}
                />
              )}
            </div>
          )}
        </div>
      </GroupedProjectListSection>
    </GroupedProjectsStyles>
  );
};

export default GroupedProjectsBlock;
