import '../../../lib/fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { StyledAnchor, StyledButton } from './styledDefaultButton';
import { isHexColorRegex, isRGBaColorRegex } from '../../../lib/util';
import { BaseBlockProps } from '../../../types'

export interface ButtonProps extends BaseBlockProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  elevation?: number;
  handleOnClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  href?: string;
  color?: string;
  icon?: IconName;
  iconEnd?: IconName;
  ghost?: boolean;
  active?: boolean;
  type?: 'button' | 'reset' | 'submit';
  theme?: 'default' | 'alternative' | 'travel' | 'square';
  border?: boolean;
  title?: string;
  target?: string;
  wide?: boolean;
  isActual?: boolean;
}

const Button = ({
  className,
  children,
  disabled = false,
  href,
  elevation = null,
  handleOnClick,
  icon,
  iconEnd = null,
  ghost,
  active,
  color = null,
  type = 'button',
  theme = 'default',
  border = false,
  title,
  target,
  wide,
  anchorId
}: ButtonProps): ReactElement => {
  const colorProp = color === 'inherit' ? 'colorInherit' : null;
  const isCSSColor =
    isHexColorRegex.test(color) || isRGBaColorRegex.test(color);
  const colorStyleProp = isCSSColor ? { color } : null;
  // const colorHex = color
  const styleNames =
    'button ' +
    `${className} ${theme ? theme : ''}` +
    `${ghost ? ' ghost' : ''}` +
    `${active ? ' active' : ''}` +
    `${colorProp ? ` ${colorProp}` : ''}` +
    `${elevation !== null ? ` elevation-${elevation}` : ''}` +
    `${wide ? ' wide' : ''}` + 
    `${border ? ' has-border' : ''}`;

  return href ? (
    <StyledAnchor
      id={anchorId}
      className={styleNames}
      style={colorStyleProp}
      ghost={ghost}
      href={href}
      title={title}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : null}
    >
      {icon && (
        <div className="icon--before">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <span>{children}</span>
      {iconEnd && (
        <div className="icon--after">
          <FontAwesomeIcon icon={iconEnd} />
        </div>
      )}
    </StyledAnchor>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <StyledButton
      id={anchorId}
      className={styleNames}
      ghost={ghost}
      disabled={disabled}
      onClick={handleOnClick}
      type={type}
      title={title}
    >
      {icon && (
        <div className="icon--before">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <span>{children}</span>
      {iconEnd && (
        <div className="icon--after">
          <FontAwesomeIcon icon={iconEnd} />
        </div>
      )}
    </StyledButton>
  );
};

export default Button;
