import { FaArrowCircleRight } from 'react-icons/fa';
import React, { ReactElement } from 'react';
import { LinkProps } from '../../../types';
import Button from '../../atoms/Button';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import StyledFeaturedContentBlock from './styles';
import { BaseBlockProps } from '../../../types'

export interface FeaturedContentBlockProps extends BaseBlockProps {
  align?: 'left' | 'right' | 'center';
  compact?: boolean;
  stretch?: boolean;
  title?: EpiProps;
  summary?: EpiProps;
  link?: LinkProps;
}

const FeaturedContentBlock = ({
  title,
  summary,
  link,
  align,
  compact = false,
  stretch = false,
  anchorId
}: FeaturedContentBlockProps): ReactElement => {
  return (
    <StyledFeaturedContentBlock id={anchorId}
      className={`${align ? `align-${align}` : ''} ${
        stretch ? 'do-stretch' : ''
      }${
        compact ? ' compact' : ''
      }`}
    >
      <header>
        {title && (
          <EpiProperty
            name={title.name}
            value={title.value}
            isEditMode={title.isEditMode}
            outerTag="h2"
          />
        )}
        {link && (
          <a
            href={link.href}
            className="gtm-track"
            title={link.label}
            target={link.target}
          >
            <FaArrowCircleRight />
            <span className="visually-hidden">{link.label}</span>
          </a>
        )}
      </header>
      <div className="content">
        {summary && (
          <EpiProperty
            name={summary.name}
            value={summary.value}
            isEditMode={summary.isEditMode}
            outerTag="p"
          />
        )}
        {link && (
          <Button
            href={link.href}
            className="button gtm-track"
            theme="alternative"
            title={link.label}
            target={link.target}
          >
            {link.label}
          </Button>
        )}
      </div>
    </StyledFeaturedContentBlock>
  );
};

export default FeaturedContentBlock;
