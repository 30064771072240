import '../../../lib/fontawesome';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';

import { SearchFormProps } from '../SearchFormWidget';
import StyledSearchForm from './styles';

function SearchForm({
  initialValue,
  className,
  minimal,
  label,
  action = '',
  placeholder = "I'm searching for",
  onSubmit,
  isHeader = false,
  searchId = 'search',
}: SearchFormProps): ReactElement {
  const [active, setActive] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);

  // if supplied a onSubmit. pass the VALUE on to that function instead of using the action.
  const onBlurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
    setActive(false);
    if (onSubmit) {
      onSubmit(ev.target.value);
    }
  };

  const onSubmitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) {
      ev.preventDefault();
      const formEl: HTMLElement = ev.target as HTMLElement;
      onSubmit(formEl.querySelector('input').value);
    }
  };

  return (
    <StyledSearchForm
      action={`${action}`}
      className={
        active
          ? `${className && className} search-form search-form--active`
          : `${className && className} search-form`
      }
      role="search"
      minimal={minimal}
      isHeader={isHeader}
      onSubmit={onSubmitHandler}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="search">
        Search <span>{label}</span>
      </label>
      <div className="search-form__input">
        <input
          type="text"
          id={searchId}
          name="q"
          value={inputValue}
          onChange={ev => setInputValue(ev.target.value)}
          placeholder={placeholder}
          aria-label="Search Main Roads"
          onFocus={(): void => setActive(true)}
          onBlur={onBlurHandler}
        />
        <button type="submit">
          <span>Submit</span>
          <FontAwesomeIcon icon="search" />
        </button>
      </div>
      <p>
        <a href="https://www.wa.gov.au/" title="To WA Gov Search" rel="">
          Or go to WA Gov Search
        </a>
      </p>
    </StyledSearchForm>
  );
}

export default SearchForm;
