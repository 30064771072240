import styled from 'styled-components';
import theme, { breakpoint, HEIGHT_BREAKPOINT } from '../../../lib/theme';
import { ContainerStyles } from '../../atoms/Container';

// background-color: ${theme.colors.backgroundLight};

export const StyledHeroCTABlock = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #2D2F32;
  position: relative;
  margin-bottom: 32px;
  ${breakpoint.greaterThan('md')`
    // display: flex;
    // flex-wrap: nowrap;
    min-height: 500px;
    margin-bottom: 64px;
    @media screen and (min-height: ${HEIGHT_BREAKPOINT}px) {
      min-height: 650px;
    }
  `}
  .hero-cta__container--outer{
    ${ContainerStyles}
  }
  .hero-cta__container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5em 1em 3em;
    z-index: 200;

    ${breakpoint.greaterThan('md')`
      width: min(45vw, 800px);
      min-width: 500px;
      flex-direction: column;
      padding: 1.5em 0em 3em 1em;
      @media screen and (min-height: ${HEIGHT_BREAKPOINT}px) {
        width: 33vw;
        width: min(33vw,500px);
        padding: 1.5em 0em 3em 1em;
      }
    `}
    ${breakpoint.greaterThan('lg')`
    `}
    ${breakpoint.greaterThan('container')`
      // padding-left: 124px;
    `};
  }
  .hero-cta__content {
    padding:  0.8em;
    background-color: #ffffff;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    gap: 0.875em;
    max-width: 500px;

    ${breakpoint.greaterThan('md')`
      /*max-width: none;*/
      padding:  1em;
      // transform: translate(64px, 0);
      @media screen and (min-height: ${HEIGHT_BREAKPOINT}px) {
        // transform: translate(125px, 0);
      }

    `}
    ${breakpoint.greaterThan('lg')`
    `}

    ${breakpoint.greaterThan('container')`
      transform: none;
    `}

    h1 {
      margin: 0 0 0.75rem 0;
      font-size: 1.75rem;
      line-height: 1.1;
      font-weight: ${theme.fontWeight.black};

      ${breakpoint.greaterThan('md')`
        margin: 0 0 1.75rem 0;
        font-size: 2.75rem;
      `}
    }

    p {
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.4;

      ${breakpoint.greaterThan('md')`
        font-size: 1rem;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: 1.125rem;
      `}
    }
  }

  .hero-cta__caption {
    position: relative;
    min-width: 100%;
    background-color: #2D2F3265;
    z-index: 999;
    padding: 0.5rem 1rem;

    p {
      color: #ffffff;
      span {
        font-size: 0.9rem;
      }
      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    &>.hero-cta__container--outer{
      display: flex;
      justify-content: space-between;
    }
  }

  .hero-cta__article {
    background-color: #008072;
    color: #ffffff;
    padding: 1em .75em;
    border-radius: 16px;
    ${breakpoint.greaterThan('xs')`
      padding: 1.25em 1.4375em;
    `}
    ${breakpoint.greaterThan('sm')`
      padding: 1.875em 1.875em ;
    `}
    h2{
      font-size: 1.25rem;
      margin-bottom: 0.25em;
      ${breakpoint.greaterThan('xs')`
        font-size: 1.5em;
      `}
      ${breakpoint.greaterThan('sm')`
        font-size: 2em;
      `};
    }
  }
  .promo-color--red {
    background-color: ${theme.colors.promoBlockTitleBgRed};
  }
  .promo-color--blue {
    background-color: ${theme.colors.promoBlockTitleBgBlue};
  }
  .promo-color--green {
    background-color: ${theme.colors.promoBlockTitleBgGreen};
  }
  .promo-color--black {
    background-color: ${theme.colors.promoBlockTitleBgBlack};
  }

  .hero-cta__article--button{
    display: flex;
    margin-top: 2em;
    justify-content: flex-end;
    align-items: flex-start;
    ${breakpoint.greaterThan('md')`
      margin-top: 40px;
    `}
  }

  .hero-cta__image {
    &.full-width-background{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
      }
    }
    z-index: 100; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* min-height: 650px; */
    ${breakpoint.greaterThan('md')`
      // position: relative;
      // height: inherit;
      flex: 1;
      min-height: 500px;
      @media screen and (min-height: ${HEIGHT_BREAKPOINT}px) {
        min-height: 650px;
      }
    `}
    img{
      display: block;
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
    img {
      z-index: 110; 
      @supports (object-fit: cover) {
      }
      ${breakpoint.greaterThan('md')`
        width: 75vw;
      `}
      ${breakpoint.greaterThan('container')`
        width: 75vw;
        width: calc(((100vw - 1440px) / 2));
        width: calc((100vw - ((100vw - 1150px) / 2) - 280px));
      `}
      ${breakpoint.greaterThan('huge')`
        width: calc((100vw - ((100vw - 1520px) / 2) - 280px));
      `}
      ${breakpoint.greaterThan('massive')`
        width: calc((100vw - ((100vw - 1640px) / 2) - 280px));
      `}
    }
  }
  .hero-cta__image {
    &.full-width-background{
      .hero-cta__player{
        width: 100% !important;
      }
    }
  }
  .hero-cta__controls {
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    z-index: 999;
    justify-content: center;
    align-items: center;

    ${breakpoint.greaterThan('sm')`
      justify-content: flex-end;
    `}

    button, a {
      all: unset;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 2.8rem;
      height: 2.8rem;
      margin: 0 0.25rem;
      background-color: #2D2F3200;
      transition-property: background-color;
      transition-duration: 300ms;
      transition-timing-function: ease-out;

      :hover {
        background-color: #2D2F3290;
      }

      ${breakpoint.greaterThan('sm')`
        margin: 0 0 0 0.5rem;
      `}

      svg {
        width: 1.1rem;
        height: 1.1rem;
        fill: #ffffff;
      }
    }
  }
  .hero-cta__player {
    display: block;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 120; 
    ${breakpoint.greaterThan('md')`
      width: 75vw !important;
    `}

    video {
      object-fit: cover;
    }
    &.is-youtube{
      overflow: hidden;
      &:after{
        content: '';
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 200;
        width: 100%;
        height: 100%;
      }
      iframe {
        z-index: 100;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        width: 1280px;
        @media screen and (min-width: 1516px) {
          width: 100%;
          height: max(650px, 56.25vw);
        }
      }
    }
  }

  .hero-cta__wwm-block {
    padding: 1em .75em;
    background-color: #e6e6e6;
    border-radius: 16px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

    ${breakpoint.greaterThan('xs')`
      padding: 1.25em 1.4375em;
    `}
    ${breakpoint.greaterThan('md')`
      padding: 1.875em 1.875em ;
    `}
    h2,
    h3{
      margin: 0;
      font-size: 1.125rem;
      margin-bottom: 0.25em;
      ${breakpoint.greaterThan('xs')`
        font-size: 1.25em;
      `}
    }
  }
  .hero-cta__wwm-block--search{
    display: flex;
    gap: 1em;
    margin-top: 1em;
    flex-direction: column;
    ${breakpoint.greaterThan('xs')`
      flex-direction: row;
      gap: 0;
    `}
    .input{
      flex: 1;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        padding: 0.5rem 0.75rem;
        border: 1px solid #C7C7C7;
        ${breakpoint.greaterThan('xs')`
          padding-left: 1em;
          width: calc(100% + 50px);
          outline-offset: -1px;
          border-right: none;
          outline-width: 1px;
          outline-style: solid;
          outline-color: #C7C7C7;
        `}
      }
    }
    .btn{
      button{
        width: 100%;
        ${breakpoint.greaterThan('xs')`
          width: auto;
        `}
      }
    }
  }
`;
