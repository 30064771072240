import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import NProgress from 'nprogress';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import Pagination from '../../molecules/Pagination';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import AccreditationListItem from '../../molecules/AccreditationListItem';
import {
  StyledAccreditationList,
  StyledAccredTypeFilterList,
  StyledButton,
  StyledClearButton,
  StyledNavBreadcrumbs,
  StyledPageLayout,
  StyledSearchFormWidget,
  StyledSelectInput,
  StyledStatusFilterList,
  StyledTypeFilterListButton,
} from './styles';

type ApiQueryProps = {
  type?: string[];
  status?: string[];
  certificationScope?: string[];
  state?: string[];
  suburb?: string[];
  pageNumber?: number;
  pageSize?: number;
  sectionFilterResults?: string;
};

type QueryStats = {
  totalItems?: number;
  page?: number;
  itemsPerPage?: number;
  certifiedSelected?: boolean;
  typeSelected?: boolean;
};

type FilterItem = {
  name: string;
  icon?: string;
  href?: string;
  selected: boolean;
};

type TypeFilterListButton = {
  active: boolean;
  label: string;
  // href?: string;
  onClick: (label, active) => void;
};

// Sidebar Type filter button
const AccredTypeFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButton): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <StyledTypeFilterListButton
      className={active ? 'active' : ''}
      type="button"
      onClick={onClickHandler}
    >
      {active && <FontAwesomeIcon icon={['far', 'times-circle']} />}
      <span>{label}</span>
      <FontAwesomeIcon icon="chevron-right" />
    </StyledTypeFilterListButton>
  );
};

// Sidebar Status filter button
const StatusFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButton): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <Button type="button" ghost active={active} handleOnClick={onClickHandler}>
      {label}
    </Button>
  );
};

// Change this base url when debugging
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/';
const APIURL = `${API_BASE_URL}/accreditation/search`;
//const APIURL = `https://run.mocky.io/v3/4c5b8315-1f81-4e1b-a4a3-8c638a5b81a1`;

interface AccreditationListPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  initialListingQuery?: string;
  sectionFilter?: string;
  itemsPerPageSetting: number;
  lastReviewed?: string;
  lastUpdated?: string;
  alert?: PageTemplateProps['alert'];
}
const AccreditationListPage = ({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  initialListingQuery,
  sectionFilter,
  itemsPerPageSetting = 10,
  lastReviewed = undefined,
  lastUpdated = undefined,
  alert,
}: AccreditationListPageProps): ReactElement => {
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [types, setTypes] = useState<FilterItem[]>([]);
  const [statuses, setStatuses] = useState<FilterItem[]>([]);
  const [certificationScopes, setCertificationScopes] = useState<FilterItem[]>(
    [],
  );
  const [states, setStates] = useState<FilterItem[]>([]);
  const [suburbs, setSuburbs] = useState<FilterItem[]>([]);
  const [apiResults, setApiResults] = useState<any[] | undefined>(undefined);
  const [queryStats, setQueryStats] = useState<QueryStats>({});

  // NOTE: The initial query can be supplied from initialSearchQuery as a prop,
  // otherwise it'll fall back to undefined
  const [searchQuery, setSearchQuery] = useState<string>(initialListingQuery);
  const [currentSection, setCurrentSection] = useState<string>(sectionFilter);

  const isMounted = useRef(false);

  // search input field
  const onSearchSubmitHandler = (val: string): void => {
    setSearchQuery(val);
  };

  // query API
  const getResults = async ({
    type,
    status,
    certificationScope,
    state,
    suburb,
    sectionFilterResults,
    pageNumber = 1,
  }: ApiQueryProps) => {
    // local front end
    // const API_BASE_URL = 'https://next.json-generator.com/api/json/get/NJ95f1YO_';
    // local front end
    NProgress.start();
    const resTypes = type ? type.map(f => encodeURIComponent(f)).join(',') : '';
    const resStatuses = status
      ? status.map(f => encodeURIComponent(f)).join(',')
      : '';
    const resCertificationScopes = certificationScope
      ? certificationScope.map(f => encodeURIComponent(f)).join(',')
      : '';
    const resStates = state
      ? state.map(f => encodeURIComponent(f)).join(',')
      : '';
    const resSuburbs = suburb
      ? suburb.map(f => encodeURIComponent(f)).join(',')
      : '';
    // assumed API call format from docs (subject to change, update if it does):
    // /api/search-content/search?q=?itemsPerPage={#}&category={category1,category2}&type={type1,type2}&page={pagenumber}&itemsPerPage={#}
    const url =
      searchQuery.split('&').length > 1
        ? `${APIURL}${searchQuery}`
        : `${APIURL}?q=${searchQuery}` +
          `&take=${itemsPerPageSetting}` +
          `&type=${resTypes}` +
          `&status=${resStatuses}` +
          `&certificationScope=${resCertificationScopes}` +
          `&state=${resStates}` +
          `&suburb=${resSuburbs}` +
          `&page=${pageNumber}` +
          `&sectionFilter=${encodeURIComponent(
            currentSection ?? sectionFilterResults,
          )}`;

    const results = await axios(url)
      .then(res => {
        NProgress.done();
        return res.data;
      })
      .catch(reason => {
        console.error('ERROR GETTING SEARCH QUERY', reason);
        NProgress.done();
        return Promise.reject(reason);
      });
    return results;
  };

  // Prepare new query...
  const updateQuery = (): void => {
    const type =
      types && types.filter(c => c.selected === true).map(c => c.name);
    const status =
      statuses && statuses.filter(c => c.selected === true).map(c => c.name);
    const certificationScope =
      certificationScopes &&
      certificationScopes.filter(c => c.selected === true).map(c => c.name);
    const state =
      states && states.filter(c => c.selected === true).map(c => c.name);
    const suburb =
      suburbs && suburbs.filter(c => c.selected === true).map(c => c.name);

    const query: ApiQueryProps = {
      type,
      status,
      certificationScope,
      state,
      suburb,
      pageNumber: currentPage,
      sectionFilterResults: currentSection,
    };

    getResults(query).then(res => {
      // console.log(res);

      // bit of a hacky way to prevent false refreshes of these comps. Resulting in a infinite loop.
      if (JSON.stringify(res.types) !== JSON.stringify(types)) {
        setTypes(res.types);
      }
      if (JSON.stringify(res.statuses) !== JSON.stringify(statuses)) {
        setStatuses(res.statuses);
      }
      if (
        JSON.stringify(res.certificationScopes) !==
        JSON.stringify(certificationScopes)
      ) {
        setCertificationScopes(res.certificationScopes);
      }
      if (JSON.stringify(res.states) !== JSON.stringify(states)) {
        setStates(res.states);
      }
      if (JSON.stringify(res.suburbs) !== JSON.stringify(suburbs)) {
        setSuburbs(res.suburbs);
      }

      if (JSON.stringify(res.items) !== JSON.stringify(apiResults)) {
        setApiResults(res.items);
      }
      setQueryStats({
        totalItems: res.totalItems,
        page: res.page,
        itemsPerPage: res.itemsPerPage,
        certifiedSelected: res.certifiedSelected,
        typeSelected: res.typeSelected,
      });
    });
  };

  // when filtering by type (sidebar)
  const onTypeClickHandler = (typeLabel: string, isActive: boolean): void => {
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newTypes = types.map(type => {
      if (type.name === typeLabel) {
        type.selected = isActive;
      }
      return type;
    });
    setTypes(newTypes);
    updateQuery();
  };

  // when filtering by status (sidebar)
  const onStatusClickHandler = (
    statusLabel: string,
    isActive: boolean,
  ): void => {
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newStatuses = statuses.map(status => {
      if (status.name === statusLabel) {
        status.selected = isActive;
      }
      return status;
    });
    setStatuses(newStatuses);
    updateQuery();
  };

  // when filtering by certification scope (sidebar)
  const onCertificationScopeClickHandler = (
    certificationScopeLabel: string,
    isActive: boolean,
  ): void => {
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newCertificationScopes = certificationScopes.map(
      certificationScope => {
        if (certificationScope.name === certificationScopeLabel) {
          certificationScope.selected = isActive;
        }
        return certificationScope;
      },
    );
    setCertificationScopes(newCertificationScopes);
    updateQuery();
  };

  // when filtering by state (sidebar)
  const onStateChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const stateLabel = event.target.value;
    console.log(stateLabel);
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newStates = states.map(state => {
      if (state.name === stateLabel) {
        state.selected = true;
      } else {
        state.selected = false;
      }
      return state;
    });
    setStates(newStates);
    updateQuery();
  };

  // when filtering by suburb (sidebar)
  const onSuburbChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const suburbLabel = event.target.value;
    console.log(suburbLabel);
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newSuburbs = suburbs.map(suburb => {
      if (suburb.name === suburbLabel) {
        suburb.selected = true;
      } else {
        suburb.selected = false;
      }
      return suburb;
    });
    setSuburbs(newSuburbs);
    updateQuery();
  };

  // when paginating
  useEffect(() => {
    if (isMounted.current) {
      updateQuery();
    }
    // eslint-disable-next-line
  }, [currentPage, searchQuery]);

  // on mount:
  useEffect(() => {
    getResults({})
      .then(res => {
        // console.log(res);
        setTypes(res.types);
        setStatuses(res.statuses);
        setCertificationScopes(res.certificationScopes);
        setStates(res.states);
        setSuburbs(res.suburbs);
        setApiResults(res.items);
        setQueryStats({
          totalItems: res.totalItems,
          page: res.page,
          itemsPerPage: res.itemsPerPage,
          certifiedSelected: res.certifiedSelected,
          typeSelected: res.typeSelected,
        });
        isMounted.current = true;
      })
      .catch(reason => console.error(reason));
    // eslint-disable-next-line
  }, [currentSection]);

  // search input field
  const onClearSubmitHandler = (): void => {
    const url = `//${window.location.host}${window.location.pathname}`;
    window.location.href = url;
  };

  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout>
          <aside className="page-layout__aside">
            {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
            <PageTitle>
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="span"
              />
            </PageTitle>
            <StyledButton
              icon="chevron-down"
              ghost
              type="button"
              handleOnClick={(): void => setShowFilters(!showFilters)}
            />
            <div
              className={`document-list__filters ${
                showFilters ? 'document-list__filters--active' : ''
              }`}
            >
              {types && types?.length > 0 && (
                <>
                  <h2>Browse by Type</h2>
                  <StyledAccredTypeFilterList>
                    {types.map((item, i) => (
                      <li
                        key={i}
                        className={item.selected ? 'is-active' : undefined}
                      >
                        <AccredTypeFilterListButton
                          active={item.selected}
                          onClick={onTypeClickHandler}
                          label={item.name}
                        />
                      </li>
                    ))}
                  </StyledAccredTypeFilterList>
                </>
              )}
              {(queryStats.typeSelected || queryStats.certifiedSelected) && (
                <h2>Filter</h2>
              )}
              {queryStats.typeSelected && (
                <>
                  {statuses && statuses?.length > 0 && (
                    <>
                      <StyledStatusFilterList>
                        {statuses.map((item, i) => {
                          return (
                            <li key={i}>
                              <StatusFilterListButton
                                active={item.selected}
                                onClick={onStatusClickHandler}
                                label={item.name}
                              />
                            </li>
                          );
                        })}
                      </StyledStatusFilterList>
                    </>
                  )}
                </>
              )}
              {queryStats.certifiedSelected && (
                <>
                  {certificationScopes && certificationScopes?.length > 0 && (
                    <StyledStatusFilterList>
                      {certificationScopes.map((item, i) => {
                        return (
                          <li key={i}>
                            <StatusFilterListButton
                              active={item.selected}
                              onClick={onCertificationScopeClickHandler}
                              label={item.name}
                            />
                          </li>
                        );
                      })}
                    </StyledStatusFilterList>
                  )}
                  {((states && states?.length > 0) ||
                    (suburbs && suburbs?.length > 0)) && (
                    <>
                      <h2>Location</h2>
                      {states && states?.length > 0 && (
                        <>
                          <label
                            htmlFor="foo"
                            className="form__input-group__label"
                          >
                            State
                          </label>
                          <StyledSelectInput onChange={onStateChangeHandler}>
                            <option value="">-- Select State --</option>
                            {states.map((item, i) => {
                              return (
                                <option
                                  key={i}
                                  value={item.name}
                                  selected={item.selected}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </StyledSelectInput>
                        </>
                      )}
                      {suburbs && suburbs?.length > 0 && (
                        <>
                          <label
                            htmlFor="foo"
                            className="form__input-group__label"
                          >
                            Suburb
                          </label>
                          <StyledSelectInput onChange={onSuburbChangeHandler}>
                            <option value="">-- Select Suburb --</option>
                            {suburbs.map((item, i) => {
                              return (
                                <option
                                  key={i}
                                  value={item.name}
                                  selected={item.selected}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </StyledSelectInput>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <h2>Search</h2>
              <StyledSearchFormWidget
                placeholder="Search name"
                searchInputWidth="100%"
                onSubmit={onSearchSubmitHandler}
              />
            </div>
            <StyledClearButton
              type="button"
              theme="alternative"
              handleOnClick={onClearSubmitHandler}
            >
              Clear Filters
            </StyledClearButton>
          </aside>
          <div className="page-layout__main">
            {apiResults && queryStats && (
              <Container>
                <p className="document-list__number">
                  {queryStats.totalItems <= queryStats.itemsPerPage ? (
                    <>
                      <strong>{queryStats.totalItems}</strong>
                      {` Operator${queryStats.totalItems !== 1 ? 's' : ''}`}
                    </>
                  ) : (
                    <>
                      <strong>
                        {queryStats.page * queryStats.itemsPerPage -
                          (queryStats.itemsPerPage - 1)}
                        {' - '}
                        {queryStats.page * queryStats.itemsPerPage}
                      </strong>
                      {' Operators of '}
                      <strong>
                        {queryStats.totalItems &&
                          queryStats.totalItems.toLocaleString()}
                      </strong>
                    </>
                  )}
                </p>
                <StyledAccreditationList>
                  {apiResults?.map((item, i) => (
                    <AccreditationListItem
                      key={i}
                      // id={item.id}
                      // externalId={item.externalId}
                      type={item.type}
                      businessName={item.businessName}
                      firstName={item.firstName}
                      lastName={item.lastName}
                      status={item.status}
                      // currentlyAccredited={item.currentlyAccredited}
                      // addressLine1={item.addressLine1}
                      // addressLine2={item.addressLine2}
                      // suburbTown={item.suburbTown}
                      // state={item.state}
                      // country={item.country}
                      // postcode={item.postcode}
                      emails={item.emails}
                      // phones={item.phones}
                      certificationScope={item.certificationScope}
                      // certificationScopeList={item.certificationScopeList}
                      phonesList={item.phonesList}
                      // emailsList={item.emailsList}
                      formattedAddress={item.formattedAddress}
                    />
                  ))}
                </StyledAccreditationList>
                {queryStats.totalItems > queryStats.itemsPerPage && (
                  <Pagination
                    forcePage={currentPage - 1}
                    pageCount={Math.ceil(
                      queryStats.totalItems / queryStats.itemsPerPage,
                    )}
                    onPageChange={(val: any): void => {
                      document.getElementById('main-content').scrollIntoView({
                        behavior: 'smooth',
                      });
                      return setCurrentPage(val.selected + 1);
                    }}
                  />
                )}
                {apiResults.length === 0 && (
                  <p>
                    No operators found for your query, please try a different
                    search.
                  </p>
                )}
              </Container>
            )}
            <PageLastReviewed
              lastReviewed={lastReviewed}
              lastUpdated={lastUpdated}
              pageType="page"
            />
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
};

export default AccreditationListPage;
