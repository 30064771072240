import { format, parse } from 'date-fns';
import { FaCopy } from 'react-icons/fa';
import { getUrlOtherSearchParams } from '../../../lib/util';

// Function to create info window and conditional render badsed on feature type, extracted for brevity

const parseDate = (dateString) => {
  return parse(dateString, 'dd/MM/yyyy HH:mm:ss', new Date());
}

const formatDate = (dateString) => {
  const date = parseDate(dateString);
  return format(date,'dd/MM/yyyy hh:mm a');
}

function toggleInfoWindowShare () {
  console.log('test')
  document.getElementById('info-window__share')?.classList.toggle('info-window__share--active')
}

const createInfoWindowDate = (feature) => {
  switch (feature?.featureType?.DisplayName) {
    case 'Alert':
    case 'Specified Conditions':
      return `<li><strong>No condition change since:</strong> ${formatDate(feature?.properties?.UpdateDate || feature?.properties?.EntryDate)}</li>`
    case 'Roadworks':
        return (feature?.properties?.DateStarte ? `<li><strong>From: </strong>${formatDate(feature?.properties?.DateStarte)}</li>` : '') + 
        (feature?.properties?.EstimatedC ? `<li><strong>To: </strong>${formatDate(feature?.properties?.EstimatedC)}</li>` : '')
    case 'Event':
      return (feature?.properties?.DateTimeSt ? `<li><strong>From: </strong>${formatDate(feature?.properties?.DateTimeSt)}</li>` : '') + 
      (feature?.properties?.DateTimeEn ? `<li><strong>To: </strong>${formatDate(feature?.properties?.DateTimeEn)}</li>` : '')
    default:
      return ((feature?.properties?.UpdateDate || feature?.properties?.DateTimeSt || feature?.properties?.EntryDate) ? (
        `<li><strong>${feature?.properties?.UpdateDate ? 'Last updated' : feature?.properties?.DateTimeSt ? 'Started' : feature?.properties?.EntryDate ? 'Entered on' : ''}:</strong> ${formatDate(feature?.properties?.UpdateDate || feature?.properties?.DateTimeSt || feature?.properties?.EntryDate)}</li>`
      ) : '')
  }
}

const createInfoWindowBody = (feature, filtersRestArea) => {
  switch (feature?.featureType?.DisplayName) {
    case 'Roadworks':
      return '<p><strong>Details</strong></p>' +
      `<p>${feature?.properties?.Descriptio ?? ''}</p>`
    case 'Specified Conditions':
    case 'Alert':
      return '<p><strong>Details</strong></p>' +
      (feature?.properties?.IncidentTy ? `<p>${feature?.properties?.IncidentTy}</p>` : '') +
      `<p>${feature?.properties?.Location ?? ''}</p>`
    case 'Event':
      return '<p><strong>Details</strong></p>' +
      `<p>${(feature?.properties?.Road === 'LOCAL ROAD' ? (feature?.properties?.LocalRoadN || feature?.properties?.LocalRoadName || feature?.properties?.Road) : feature?.properties?.Road)}</p>` + 
      `<p>${feature?.properties?.EventName ?? ''}</p>` + 
      `<p>${feature?.properties?.EventDescr ?? ''}</p>`
    case 'Rest Areas':
      return '<p><strong>Amenities</strong></p>' +
      '<ul class="info-window__columns info-window__amenities">' +
        filtersRestArea
        // Filter for amenities for this specific rest area
        .filter(filter => filter.value === 'Count' ? feature?.properties?.[filter.prop] > 0 : feature?.properties?.[filter.prop] === filter.value)
        .map(filter =>
          '<li>' + 
            (filter?.iconUrl ? `<figure><img src="${filter.iconUrl}--white.svg" alt="${filter.name} Icon" /></figure>` : '') + 
            `<span>${filter.name}</span>` +
          '</li>'
         ).join('') + 
      '</ul>'
    default:
      return '<p><strong>Details</strong></p>' +
      `<p>${(feature?.properties?.Road === 'LOCAL ROAD' ? (feature?.properties?.LocalRoadN || feature?.properties?.LocalRoadName || feature?.properties?.Road) : feature?.properties?.Road) || feature?.properties?.Intersecti || feature?.properties?.COMMON_USAGE_NAME || ''}</p>` + 
      `<p>${feature?.properties?.Descriptio || feature?.properties?.EventDescr || feature?.properties?.SignalStat || feature?.properties?.Location || ''}</p>`
  }
}

const createInfoWindow = (feature, filtersRestArea) => {
    const contentString =
    '<div class="info-window__main">' +
      `<div class="info-window__title" style="background-color:${feature?.featureType?.IconColor ?? '#054E66'}">` +
        `<img src="${feature?.featureType?.IconUrl}--white.svg" alt="${feature?.featureType?.DisplayName} Icon" />` +
        `<h4>${(feature?.properties?.Road === 'LOCAL ROAD' ? (feature?.properties?.LocalRoadN || feature?.properties?.LocalRoadName || feature?.properties?.Road) : feature?.properties?.Road) || feature?.properties?.Intersecti || feature?.properties?.COMMON_USAGE_NAME || ''}</h4>` +
      '</div>' +
      '<div class="info-window__body">' +
        '<div class="info-window__feature-type">' +
          `<h5>${feature?.featureType?.DisplayName ?? ''}</h5>` +
        '</div>' +
        '<ul class="info-window__date">' +
          createInfoWindowDate(feature) +
        '</ul>'+
        // Create contextual body text
        createInfoWindowBody(feature, filtersRestArea) +
        // Traffic Impacts
        (feature?.properties?.TrafficImp ? (
          `<p>${feature?.properties?.TrafficImp}</p>`
        ): '') + 
      '</div>' +
      '<button id="info-window__share" class="info-window__share" title="Copy to Clipboard">' +
        '<div>' +
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z"/></svg>' +
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"/></svg>' +
        '</div>' + 
        `<p>${ window.location.href + window.location.pathname + getUrlOtherSearchParams('fid') + '&fid=' + feature?.properties?.Id }</p>` + 
      '</button>' +
      '<div class="info-window__footer">' +
        (feature?.properties?.SeeMoreUrl?.trim() ? `<a href="${feature?.properties?.SeeMoreUrl}" target="_blank">Find out more</a>` : '') +
        `<button id="info-window__share-button">Share</button>` +
      '</div>' +
    '</div>';

    return contentString
}

export default createInfoWindow