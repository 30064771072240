import React, { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';

import { StyledPagination } from './styles';

export interface PaginationProps {
  forcePage?: number; // To override selected page with parent prop.
  pageCount: number; // Required. The total number of pages.
  onPageChange: any;
  skipHrefUpdate?: boolean;
}

function Pagination({
  forcePage,
  pageCount,
  onPageChange,
  skipHrefUpdate = false
}: PaginationProps): ReactElement {
  if (pageCount <= 1) {
    return null;
  }
  const onPageChangeHandler = ev => {
    /* Scroll to top code:
    document.getElementById('main-content').scrollIntoView({
      behavior: 'smooth',
    }); 
    */
    // history.push()
    // console.log('onPageChangeHandler', ev);
    if (!skipHrefUpdate)
    {
      if (window && window.history && window.history.pushState) {
        const pageNumber = ev.selected + 1;
        const location = window?.location;
        const base = location.pathname || '/';
        let newUrl = `${base}`;

        // check if we are already paginating
        if (location.href.indexOf('page=') >= 0) {
          // we should have a query set (q=) so page should be on (&)
          if (location.href.indexOf('&') >= 0) {
            // potentially many params set
            // find which param we should adjust
            const urlSplit = location.href.split('&');
            let i = 0;
            let qIndex = 0;
            urlSplit.forEach(item => {
              if (item.indexOf('page=') === 0) {
                qIndex = i;
              }
              i += 1;
            });
            // found the page param. Change it to new pagenumber
            urlSplit[qIndex] = `&page=${pageNumber}`;
            // flatten back into a string
            newUrl = urlSplit.join('');
            // remove the host origin
            newUrl = newUrl.substr(location.origin.length);
          } else {
            // something weird going on. Just append to end...
            newUrl = `${base}${location.search}&page=${pageNumber}`;
          }
        } else {
          // not yet paginating. Just add page nmber in the end
          newUrl = `${base}${location.search}&page=${pageNumber}`;
        }
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
    }
    onPageChange(ev);
  };
  return (
    <StyledPagination>
      <ReactPaginate
        forcePage={forcePage}
        pageCount={pageCount}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        onPageChange={onPageChangeHandler}
      />
    </StyledPagination>
  );
}

export default Pagination;
