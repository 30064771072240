import { ReactElement } from 'react';
import Button, { ButtonProps } from '../../atoms/Button';

const ButtonBlock = ({
  children,
  disabled = false,
  href,
  color = null,
  elevation = null,
  handleOnClick,
  icon,
  iconEnd = null,
  type = 'button',
  theme = 'default',
  title,
  target = '_self',
  wide,
  ghost = null
}: ButtonProps): ReactElement => {
  return (
    <Button
      disabled={disabled}
      href={href}
      color={color}
      elevation={elevation}
      className="gtm-track"
      handleOnClick={handleOnClick}
      icon={icon}
      iconEnd={iconEnd}
      type={type}
      theme={theme}
      title={title}
      target={target}
      wide={wide}
      ghost={ghost}
    >
      {children}
    </Button>
  );
};

export default ButtonBlock;
