import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { TravelMapAlertsStyled } from './TravelMapAlertsStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
import Input from '../../atoms/Input';
import theme from '../../../lib/theme';
import { FaRedoAlt, FaSearch, FaSortAmountDown, FaSortAmountUp, FaTimes } from 'react-icons/fa';
import Loaderizer from '../../atoms/Loaderizer';
import throttle from 'lodash.throttle';
import Dropdown from '../../atoms/Dropdown';
import Button from '../../atoms/Button';
import { format, formatDistance, parse } from 'date-fns';


export interface ITravelMapAlerts {
}

const TravelMapAlerts = ({
}: ITravelMapAlerts) => {
  const { state, dispatch } = useContext(TravelMapContext);
  const { lastUpdated, alerts: {search, filters, alerts } } = state
  const searchInput = useRef(null)

  const [filteredList, setFilteredList] = useState([]);
  const [sortBy, setSortBy] = useState(['Last updated']);
  const [sortDir, setSortDir] = useState(false);

  const [regions, setRegions] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);

  const [types, setTypes] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);

  const [updatedText, setUpdatedText] = useState('Updating');

  const parseDate = (dateString) => {
    return parse(dateString, 'dd/MM/yyyy HH:mm:ss', new Date());
  }

  const formatDate = (dateString) => {
    const date = parseDate(dateString);
    return format(date, 'dd/MM/yyyy hh:mm a');
  }

  const clearSearch = () => {
    searchChange('');
    searchInput.current.value = '';
    searchInput.current.classList.remove('travel-map-alerts__search--filled');
  }

  // Reset list state
  const clearSearchAndFilters = () => {
    clearSearch();
    setRegionFilter([]);
    setTypeFilter([]);
    setSortDir(false);
    setSortBy(['Last updated']);
  }

  const updateInfo = (i) => {
    const id = parseInt(i);
    if (!Number.isNaN(id)) {
      const action = { type: 'INFO_SET', value: { id } };
      dispatch(action);
    }
  }

  // Functions to update search state
  const searchChange = (term) => {
    if (term !== search) {
      const action = { type: 'ALERTS_SET_SEARCH', value: term };
      dispatch(action);
    }
  }

  const onSearchChange = (e) => {
    const newTerm = e.target.value;
    searchChange(newTerm);
    if (newTerm) {
      searchInput.current.classList.add('travel-map-alerts__search--filled');
    } else {
      searchInput.current.classList.remove('travel-map-alerts__search--filled');
    }
  }

  const debouncedSearchChange = useMemo(
    () => throttle(onSearchChange, 300)
    , []);


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {  //checks whether the pressed key is "Enter"
      e.preventDefault();
      onSearchChange(e);
    }
  };

  // Create and filter alert list
  useEffect(() => {

    const featureTypes = filters.filter(filter => filter.Name !== 'RestAreas').map(filter => filter.DisplayName);
    setTypes(featureTypes);

    // Create a unique array of regions using ES6 Set values and filter out empty entries
    // https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
    const regions = Array.from(new Set([].concat.apply([], alerts.map((alert) => alert?.properties?.Region?.split(',')))).values()).filter(region => (region ?? '').trim());
    setRegions(regions);

    // Create lowercase search term
    const searchTerm = search.toLowerCase();

    // Set sort function based on sortby option, else maintain order (inc typecasting sort function to except 0 returns)
    let sortFunction = (a, b): (-1 | 0 | 1) => sortDir ? -1 : 1;
    switch (sortBy[0]) {
      case 'Alphabetical':
        sortFunction = (a, b) => {
          // If recent alert set to top
          if (b?.recentAlert) return 1
          // Use roads/locations depending on alphabetical order
          const val1 = a.properties?.Descriptio || a.properties?.EventDescr || a.properties?.SignalStat || a.properties?.Location || '';
          const val2 = b.properties?.Descriptio || b.properties?.EventDescr || b.properties?.SignalStat || b.properties?.Location || '';

          if (val1 < val2) {
            return sortDir ? 1 : -1;
          } else if (val1 == val2) {
            return 0;
          } else {
            return sortDir ? -1 : 1;
          }
        }
        break;
      case 'Last updated':
        sortFunction = (a, b) => {
          // If recent alert set to top
          if (b?.recentAlert) return 1
          // Use dates depending on feature type else set to last
          const val1 = parseDate(a.properties?.UpdateDate || a.properties?.EntryDate || a.properties?.DateTimeSt)
          const val2 = parseDate(b.properties?.UpdateDate || b.properties?.EntryDate || b.properties?.DateTimeSt)

          if (val1 < val2) {
            return sortDir ? -1 : 1;
          } else if (val1 == val2) {
            return 0;
          } else {
            return sortDir ? 1 : -1;
          }
        }
        break;
    }

    // Filter by search and region
    let filteredAlertsList = alerts
      .filter((alert) => {
        return (
          // Remove lines from alert list to remove duplicates
          !alert?.featureType?.Name?.includes('Lines')
        ) && (!typeFilter.length ||
          // Check if alert has any region filtered for if there are region filters
          typeFilter.includes(alert?.featureType?.DisplayName)
          ) && (!regionFilter.length ||
            // Check if alert has any region filtered for if there are region filters
            !!regionFilter.filter(value => alert?.properties?.Region.includes(value)).length
          ) &&
          // Search term included in specific properties
          (alert?.properties?.Descriptio?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.EventDescr?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.SignalStat?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.Location?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.Road?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.SignalStat?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.LocalRoadN?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.LocalRoadName?.toLowerCase()?.includes(searchTerm) ||
            alert?.properties?.Intersecti?.toLowerCase()?.includes(searchTerm))
      })
      .sort(sortFunction);
      //.sort(sortFunction);

    setFilteredList(filteredAlertsList);

  }, [alerts, search, regionFilter, typeFilter, sortDir, sortBy]);

  const formatLastUpdated = (date) => {
    return date ? 'Updated ' + formatDistance(date, new Date()) + ' ago' : 'Updating'
  }

  useEffect(() => {
    const text = formatLastUpdated(lastUpdated);
    setUpdatedText(text);
  }, [lastUpdated])

  useEffect(() => {
    setInterval(() => {
      const text = formatLastUpdated(lastUpdated);
      setUpdatedText(text);
    } , 30000)
  }, [])

  return (
    <TravelMapAlertsStyled>
      <div className='travel-map-alerts__heading'>
        <h2>Alerts Search</h2>
        <button className={'travel-map-alerts__button'} onClick={() => { clearSearchAndFilters() }} title="Reset Journey">
          <FaRedoAlt />
        </button>
      </div>
      <Input id='travel-map-alerts__search'
        className="travel-map-alerts__search"
        icon={<FaSearch />}
        iconBgColor={theme.colors.inputBg}
        label="Search for Alerts"
      >

        <>
          <input
            id='travel-map-alerts__search'
            ref={searchInput}
            placeholder="Search for an alert"
            onChange={debouncedSearchChange}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <button
            onClick={() => clearSearch()}
            title="Clear Search Input"
          >
            <FaTimes />
          </button>
        </>
      </Input>
      <div className='travel-map-alerts__filters'>
        <div>
          <p>Sort By</p>
          <Dropdown name="type" buttonText='Type' multi={true} options={types} selected={typeFilter} setSelected={setTypeFilter} />
          <Dropdown name="sortby" options={['Last updated', 'Alphabetical', 'Alert type']} selected={sortBy} setSelected={setSortBy} />
          <Dropdown name="region" buttonText='Region' multi={true} options={regions} selected={regionFilter} setSelected={setRegionFilter} />
        </div>
        <button onClick={() => { setSortDir(!sortDir) }} title={sortDir ? 'Sort Ascending' : 'Sort Descending'}>
          {sortDir ?
            <FaSortAmountDown />
            :
            <FaSortAmountUp />
          }
        </button>
      </div>
      <ul>
        {!alerts?.length ? (
          <li className='travel-map-alerts__loader'>
            <Loaderizer />
          </li>
        ) : filteredList.length === 0 ? (
          <li className='travel-map-alerts__no-results'>
            <h5>No Results Found</h5>
            <button onClick={() => clearSearchAndFilters()}>Clear Search and Filters</button>
          </li>
        ) : (
          <>
            {filteredList.map((alert) => (
              <li key={alert.id} className={alert?.recentAlert ? 'travel-map-alerts__alert travel-map-alerts__alert--recent' : 'travel-map-alerts__alert'}>
                <div>
                  <div className='travel-map-alerts__icon' style={{ backgroundColor: alert.featureType.IconColor }}>
                    <img src={alert.featureType.IconUrl + '--white.svg'} alt={`${alert.featureType.DisplayName} Icon`} width="20" height="20" />
                  </div>
                </div>
                <div>
                  <p
                    tabIndex={0}
                    role="link"
                    onClick={() => {
                      updateInfo(alert?.properties?.Id);
                    }}
                  >
                    {alert.properties?.Descriptio || alert.properties?.EventDescr || alert.properties?.Intersecti || alert.properties?.Location || ''}
                  </p>
                  <p>{(alert?.properties?.Road === 'LOCAL ROAD' ? (alert?.properties?.LocalRoadN || alert?.properties?.LocalRoadName || alert?.properties?.Road) : alert?.properties?.Road) || alert.properties?.SignalStat}</p>
                  {(!!alert.properties?.UpdateDate || !!alert.properties?.EntryDate || alert.properties?.DateTimeSt) &&
                    <p>
                      {formatDate(alert.properties?.UpdateDate || alert.properties?.EntryDate || alert.properties?.DateTimeSt)}
                    </p>
                  }
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
      <div className='travel-map-alerts__status'>
        <p>{filteredList.length} results<br />{updatedText}</p>
        <Button
          color={theme.colors.travelMapButtonBg}
          handleOnClick={(): void => {
            try {
              // Work around for print() not working in safari
              // https://qa.wujigu.com/qa/?qa=1007690/printing-safari-print-issue-with-javascript-window-print
              document.execCommand("print", false, null);
            } catch (e) {
              window.print();
            }
          }}
          theme={'square'}
        >
          Print
        </Button>
      </div>
    </TravelMapAlertsStyled>
  );
};

export default TravelMapAlerts;