import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { ReactElement, useRef, useState } from 'react';
import { FaExternalLinkAlt, FaPause, FaPlay, FaStop, FaUndoAlt } from 'react-icons/fa';
import ReactPlayer from 'react-player/lazy';

import { ImageProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { WhatWeManageWidgetProps } from '../../widgets/WhatWeManageWidget';
import ButtonBlock from '../ButtonBlock';
import { StyledHeroCTABlock } from './StyledHeroCTABlock';

export interface HeroCTABlockProps extends WhatWeManageWidgetProps {
  epiTitle: EpiProps;
  epiContent: EpiProps;
  summary: EpiProps;
  cta: string;
  button?: {
    href: string;
    icon?: IconName;
  };
  image?: ImageProps;
  video?: {
    source: string;
  };
  showWhatWeManageWidget?: boolean;
  fullWidthBackground?: boolean;
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
  colour?: 'red' | 'blue' | 'green' | 'black';
}

const HeroCTABlock = ({
  cta,
  epiContent,
  button,
  image,
  video,
  epiTitle,
  showWhatWeManageWidget = true,
  fullWidthBackground = true,
  title,
  summary,
  summaryHref,
  searchPlaceholder,
  searchAction,
  caption = '',
  attributionTo = '',
  attributionLink = '',
  colour = 'green',
}: HeroCTABlockProps): ReactElement => {
  const videoIsYoutube = video?.source
    ? video?.source.indexOf('.mp4') > 0
      ? false
      : true
    : null;

  const player = useRef(null);

  const [playing, setPlaying] = useState(false);

  // const [videoReady, setVideoReady] = useState(false);

  return (
    <StyledHeroCTABlock>
      <div className="hero-cta__container--outer padded">
        <div className="hero-cta__container">
          <div className="hero-cta__content">
            <div className={`${colour ? `hero-cta__article promo-color--${colour}` : 'hero-cta__article'}`}>
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="h2"
              />
              <EpiProperty
                name={epiContent.name}
                value={epiContent.value}
                isEditMode={epiContent.isEditMode}
                outerTag="p"
              />

              {button && (
                <div className="hero-cta__article--button">
                  <ButtonBlock
                    href={button.href}
                    icon={button.icon}
                    type="button"
                    elevation={1}
                    title={cta}
                  >
                    {cta}
                  </ButtonBlock>
                </div>
              )}
            </div>
            {showWhatWeManageWidget && (
              <div className="hero-cta__wwm-block">
                <EpiProperty
                  name={title.name}
                  value={title.value}
                  isEditMode={title.isEditMode}
                  outerTag="h3"
                />
                {!!summaryHref ? (
                  <a href={summaryHref} title={summary.value} target="_self">
                    <EpiProperty
                      name={summary.name}
                      value={`${summary.value}&nbsp;»`}
                      isEditMode={summary.isEditMode}
                      outerTag="p"
                    />
                  </a>
                ) : (
                  <EpiProperty
                    name={summary.name}
                    value={summary.value}
                    isEditMode={summary.isEditMode}
                    outerTag="p"
                  />
                )}
                <form
                  action={searchAction}
                  className="hero-cta__wwm-block--search"
                >
                  <div className="input">
                    <label
                      htmlFor="search-hero-cta"
                      className="visually-hidden"
                    >
                      Search Main Roads
                    </label>
                    <input
                      type="text"
                      id="search-hero-cta"
                      name="q"
                      autoComplete="off"
                      placeholder={searchPlaceholder}
                      aria-label={searchPlaceholder}
                      defaultValue=""
                    />
                  </div>
                  <div className="btn">
                    <ButtonBlock
                      type="submit"
                      title="Search"
                      theme="alternative"
                    >
                      Search
                    </ButtonBlock>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="hero-cta__caption"
      >
        <div
          className="hero-cta__container--outer padded"
        >
          <p>
            {!!caption && caption}
            {!!attributionTo && (
              <>
                {!!caption && !!attributionTo && <br />}
                <span>{!!attributionLink ? (<a href={attributionLink} target="_blank">{attributionTo}</a>) : attributionTo}</span>
              </>
            )}
          </p>
          <div className='hero-cta__controls'>
            {!!video?.source &&
              <>
                <button
                  title={playing ? 'Pause Video' : 'Start Video'}
                  onClick={() => {
                    setPlaying(!playing);
                  }}
                >
                  {playing ? <FaPause></FaPause> : <FaPlay></FaPlay>}
                </button>
              </>
            }
            {!!image &&
              <>
                <a
                  title="Click to view image"
                  href={image.src}
                  target="_blank"
                >
                  <FaExternalLinkAlt />
                </a>
              </>
            }
          </div>
        </div>
      </div>
      {image && (
        <div
          className={`hero-cta__image ${fullWidthBackground ? 'full-width-background' : ''
            }`}
        >
          <img
            // srcSet="header-650w.jpg 650w,
            //  header-1024w.jpg 1024w,
            //  header-full.jpg 1920w"
            srcSet={`${image.src} 650w,
            ${image.src} 1024w,
            ${image.src} 1920w`}
            sizes="100vw"
            src={image.src} // 1920px
            alt={image.alt}
          />
        </div>
      )}
      {!!video?.source && (
        <div
          className={`hero-cta__image ${fullWidthBackground ? 'full-width-background' : ''
            }`}
          onClick={() => {
            setPlaying(!playing);
          }}
        >
          <ReactPlayer
            ref={player}
            url={video.source}
            config={
              videoIsYoutube && {
                youtube: {
                  playerVars: {
                    modestbranding: 0,
                    rel: 0,
                  },
                },
              }
            }
            loop
            volume={0}
            muted
            playsinline
            playing={playing}
            className={`${videoIsYoutube ? 'is-youtube' : 'is-videofile'
              } hero-cta__player`}
            width="100%"
            height="100%"
            onReady={() => {
              // Only autoplay if screen width above 768 pixels
              if (window.innerWidth >= 768) setPlaying(true);
            }}
          // onStart={() => {
          //   //setVideoReady(true);
          // }}
          />
        </div>
      )}
    </StyledHeroCTABlock>
  );
};

export default HeroCTABlock;
