// import { IconName } from '@fortawesome/fontawesome-common-types';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { RoadWorkItemStyles, RoadWorksFeedStyles } from './RoadWorksFeedStyles';
import { BaseBlockProps } from '../../../types';

/* const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  process.env.NEXT_PUBLIC_ROADWORKS_API ||
  process.env.REACT_APP_API_BASE_URL ||
  '/';

const ROADWORKS_API = `${API_BASE_URL}/roads/roadworks`; */

type RoadworksType =
  | 'INTERMITTENT_CLOSURE'
  | 'CLOSED'
  | 'LANE_CLOSURE'
  | 'ON_GOING'
  | 'DETOUR';

export interface IRoadWorkItem {
  name: string;
  dates: string;
  times: string;
  caption?: EpiProps;
  roadworksType: RoadworksType;
  roadworksTypeName: string;
  travelmapLink?: string;
  travelmapLinkText?: string;
  statusIcon: IconName;
  linkIcon?: IconName;
}
function RoadWorkItem({
  data,
  shimmer = false,
}: {
  data?: IRoadWorkItem;
  shimmer?: boolean;
}): ReactElement {
  const {
    name,
    dates,
    times,
    caption,
    roadworksType,
    roadworksTypeName,
    travelmapLink,
    travelmapLinkText,
  } = shimmer
    ? {
        name: 'Lorem ipsum dolor',
        dates: 'sit amet consectetur',
        times: 'iste sequi voluptatum adipisicing elit. Modi',
        roadworksType: 'veniam qui esse tempora i',
        roadworksTypeName: 'veniam qui esse tempora i',
        travelmapLink: '#8',
        travelmapLinkText: 'Travel Map',
        caption: {
          name: 'caption',
          value: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut`,
          isEditMode: false,
          isHTMLContent: true,
        },
      }
    : data;
  return (
    <RoadWorkItemStyles className={shimmer ? 'shimmering' : ''}>
      <p className="roadname">{name}</p>
      <p className="timeframe">
        <span className="daterange">
          <time>{dates}</time>
        </span>{' '}
        <span className="timeofday">{times}</span>
      </p>
      <div className="meta">
        <div className="roadworks__type">
          <FontAwesomeIcon icon={data.statusIcon} />
          <span>{roadworksTypeName}</span>
        </div>
        {travelmapLink && (
          <a href={travelmapLink} className="roadworks__link">
            {data.linkIcon && <FontAwesomeIcon icon={data.linkIcon} />}
            <span>{data.travelmapLinkText}</span>
          </a>
        )}
      </div>
      {caption && (
        <EpiProperty
          name={caption.name}
          value={caption.value}
          isEditMode={caption.isEditMode}
          isHTMLContent={caption.isHTMLContent}
          outerTag="div"
          className="caption"
        />
      )}
    </RoadWorkItemStyles>
  );
}

export interface IRoadworksFeedBlock extends BaseBlockProps {
  title: string;
  information: string;
  roadworksList?: IRoadWorkItem[];
  isSidebar?: boolean;
}
function RoadworksFeedBlock({
  title,
  information,
  roadworksList,
  isSidebar,
  anchorId,
}: IRoadworksFeedBlock) {
  /* const [mounted, setMounted] = useState(false);
  const [roadworksList, setRoadworksList] = useState<IRoadWorkItem[]>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjects = async () => {
    setIsLoading(true);
    await axios(ROADWORKS_API)
      .then(res => res.data)
      .then(data => {
        setRoadworksList(data);
        setIsLoading(false);
        return data;
      })
      .catch(reason => console.error(reason));
  };

  useEffect(() => {
    if (mounted === true) {
      return undefined;
    }
    fetchProjects();
    setMounted(true);
    return () => {};
  }, []); */

  return (
    <RoadWorksFeedStyles
      id={anchorId}
      className={isSidebar ? 'is-sidebar' : ''}
    >
      <header>{title && <h2>{title}</h2>}</header>
      <div className="info">{information && <p>{information}</p>}</div>
      <div className="roadworks__feed">
        {/* {isLoading ? (
          <ul>
            {[...Array(5)].map((val, i) => (
              <RoadWorkItem shimmer key={i} />
            ))}
          </ul>
        ) : (
          <ul>
            {roadworksList?.map((item, i) => (
              <RoadWorkItem data={item} key={i} />
            ))}
          </ul>
        )} */}
        {roadworksList && (
          <ul className="roadworks__feed__item">
            {roadworksList?.map((item, i) => (
              <RoadWorkItem data={item} key={i} />
            ))}
          </ul>
        )}
      </div>
    </RoadWorksFeedStyles>
  );
}

export default RoadworksFeedBlock;
