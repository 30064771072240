import React, { ReactElement } from 'react';
import ToggleCheckboxFilterStyled from './styles';

export type ToggleCheckboxFilterProps = {
  id: string;
  value: string;
  label?: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function ToggleCheckboxFilter({
  id,
  value,
  label,
  checked = true,
  onChange,
}: ToggleCheckboxFilterProps): ReactElement {
  return (
    <ToggleCheckboxFilterStyled>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        name={id}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-checkbox" />
    </ToggleCheckboxFilterStyled>
  );
}

export default ToggleCheckboxFilter;
