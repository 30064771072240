import React, { ReactElement } from 'react';
import StyledToggleCheckbox from './styles';

export type ToggleCheckboxProps = {
  id: string;
  value: string;
  label: string;
  checked?: boolean;
  toggleColor?: string;
  checkedColor?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function ToggleCheckbox({
  id,
  value,
  label,
  checked = true,
  toggleColor = '#ffc0cb',
  checkedColor = '#7BE382',
  onChange,
}: ToggleCheckboxProps): ReactElement {
  return (
    <StyledToggleCheckbox toggleColor={toggleColor} checkedColor={checkedColor}>
      <input
        id={id}
        name={id}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-checkbox" />
      <label htmlFor={id}>{label}</label>
    </StyledToggleCheckbox>
  );
}

export default ToggleCheckbox;
