import React, { ReactElement } from 'react';
import { BaseBlockProps } from '../../../types'

import {
  StyledLinkList,
  StyledList,
  StyledListItem,
  StyledSubtitle,
  StyledTitle,
} from './styles';

export interface LinkItem {
  href: string;
  target?: '_self' | '_blank';
  title: string;
}

export interface EPIString {
  name: string;
  value: string;
  isEditMode?: boolean;
}

export interface EPILink {
  href: string;
  target?: string;
  title: string;
  isEditMode?: boolean;
}

export interface LinkListProps extends BaseBlockProps {
  title?: string;
  subtitle?: string;
  url?: string;
  target?: '_self' | '_blank';
  summary?: string;
  linksTitle?: string;
  links?: LinkItem[];
}

const LinkList = ({
  title,
  url,
  target,
  subtitle,
  summary,
  linksTitle,
  links,
  anchorId
}: LinkListProps): ReactElement => {
  return (
    <StyledLinkList id={anchorId}>
      {title && (
        <StyledTitle>
          {url ? (
            <a href={url} target={target} title={title}>
              <span>{title}</span>
            </a>
          ) : (
            <span>{title}</span>
          )}
        </StyledTitle>
      )}
      {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {summary && <p>{summary}</p>}

      {links?.length > 0 && (
        <>
          {linksTitle && <p>{linksTitle}</p>}
          <StyledList>
            {links.map((item: LinkItem, i) => (
              <StyledListItem key={i}>
                <a href={item.href} target={item.target} title={item.title}>
                  {item.title}
                </a>
              </StyledListItem>
            ))}
          </StyledList>
        </>
      )}
    </StyledLinkList>
  );
};

export default LinkList;
