// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fab, far, fas);

export const FasIconNameList = Object.values(fas)?.map(
  (icon): string => icon?.iconName,
);
FasIconNameList.unshift('');
