import React, { ReactElement } from 'react';
import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';
// import chevron from './chevron-right-solid.svg';

const StyledNavBreadcrumbs = styled.nav`
  ul {
    margin: 0 0 12px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0 1.5em 0.35em 0;
    ${breakpoint.greaterThan('md')`
      // margin: 0 2.5em 0.35em 0;
    `}
    line-height: 1;

    &:after {
      position: absolute;
      top: 50%;
      right: -1.1rem;
      width: 0.5rem;
      height: 0.5rem;
      content: '';
      background-image: url('/images/chevron-right-solid.svg');
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
    &:last-of-type {
      margin-right: 0;
      &:after {
        display: none;
      }
      span {
        border-bottom: none;
        color: ${theme.colors.textDark};
      }
    }
  }

  a {
    border-bottom: dotted 1px ${theme.colors.breadcrumb};
    color: ${theme.colors.breadcrumb};
    font-size: 0.875rem;
    font-weight: ${theme.fontWeight.medium};
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    line-height: 1;
    display: inline-block;
    &:hover {
      color: ${theme.colors.linkHover};
      border-bottom: solid 1px ${theme.colors.linkHover};
    }
  }

  span {
    color: ${theme.colors.linkHover};
    border-bottom: dotted 1px ${theme.colors.linkHover};
    font-size: 0.875rem;
    font-weight: ${theme.fontWeight.medium};
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }
`;

export interface NavBreadcrumbsProps {
  className?: string;
  items: {
    href: string;
    target?: string;
    title: string;
    active?: boolean;
  }[];
}

function NavBreadcrumbs({
  className,
  items,
}: NavBreadcrumbsProps): ReactElement {
  return (
    <StyledNavBreadcrumbs
      className={className}
      role="navigation"
      aria-label="NavBreadcrumbs"
    >
      <ul>
        {items.map(({ href, target = '_self', title, active }, i) => (
          <li key={i}>
            {active ? (
              <span>{title}</span>
            ) : (
              <a href={href} target={target} title={title}>
                {title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </StyledNavBreadcrumbs>
  );
}

export default NavBreadcrumbs;
