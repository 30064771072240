import styled from 'styled-components';
import theme from '../../../lib/theme';

export const TravelMapRouteAlertsStyled = styled.section`

  >ul, >div, .accordian__content>ul {
    list-style-type: none;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0.5rem 0rem;
    overflow: hidden;
    border-radius: 8px;
    color: #fff;

    .accordian__title {
      border-radius: 0px;
      margin: 0;
      padding: 0.5rem 1rem;
      background-color: transparent;
      gap: 0.5rem;

      .travel-map-route-alerts__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0 0.5rem 0 0;
        -webkit-print-color-adjust: exact !important;

        @media print {
          background-color: ${theme.colors.textDark};
        }

        >img {
          width: 1.2rem;
          height: 1.2rem;
          height: auto;
        }
      }
    }

    >li {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.5rem;
      margin: 0;
      padding: 0.5rem 1rem;

      p {
        color: #fff;
        margin: 0;
        &.travel-map-route-alerts__link {
          display: inline-block;
          font-size: 0.9rem;
          font-weight: 400;
          margin: 0 0.5rem 0 0;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .travel-map-route-alerts__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        -webkit-print-color-adjust: exact !important;

        @media print {
          background-color: ${theme.colors.textDark};
        }

        >img {
        width: 1.2rem;
        height: 1.2rem;
          height: auto;
        }
      }

      @media print {
        color: ${theme.colors.textDark};
        background-color: white;
        p {
          color: ${theme.colors.textDark};
        }
      }
    }
  }

  .accordian__content {
    >ul {
      padding: 0 0.5rem;
    }
  }

  @media print {
    .accordian__title {
      color: ${theme.colors.textDark};
      >svg, >img {
        display: none;
      }
    }

    .accordian__content {
      max-height: none;

      >ul {
        color: ${theme.colors.textDark};
        background-color: white;
        p {
          color: ${theme.colors.textDark};
        }
      }

      h6 {
        color: ${theme.colors.textDark};
      }
    }
  }
`;
