import { AnyARecord } from 'dns';
import React, { isValidElement, ReactElement, ReactNode } from 'react';

export interface EpiProps {
  isEditMode?: boolean;
  name: string;
  className?: string;
  isHTMLContent?: boolean;
  outerTag?:
    | string
    | React.FunctionComponent<{
        className?: string;
        dangerouslySetInnerHTML: { __html: string };
      }>;
  // Use any instead of "string | ReactNode", to appease typescript in other contexts (i.e when Epiprops is used to feed other tags)
  // Type guards are in place
  value: any;
}

const EpiProperty = ({
  name,
  className = '',
  value,
  isHTMLContent = false,
  isEditMode = false,
  outerTag: Element = 'div',
}: EpiProps): ReactElement => {
  // Check if value is ReactNode to allow for component compilations in storybook with components using EpiProps
  // https://react.dev/reference/react/isValidElement
  if (isValidElement(value)) {
    return <>{value}</>;
  }

  // Used to assert that value is not a string
  if (typeof value !== "string" || !value || value.length <= 0) {
    return null;
  }

  if (isEditMode === true) {
    return (
      <Element
        data-epi-edit={name}
        className={className}
        dangerouslySetInnerHTML={{ __html: value! }}
      />
    );
  }

  return (
    <Element
      className={`${className ? className : ''} ${
        isHTMLContent ? 'hasHTMLContent' : ''
      }`}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default EpiProperty;
