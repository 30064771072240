import styled, { css } from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledDefaultButton = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  border: 0.0625em solid transparent;
  border-radius: 100px;
  color: #000000;
  background-color: #ffffff;
  padding: 0.75em 1.5em;
  font-size: 0.875em;
  font-weight: ${theme.fontWeight.bold};
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  appearance: none;

  &.active {
    background-color: ${theme.colors.primary};
    color: #ffffff;

    &.elevation-0 {
      box-shadow: ${theme.elevation[2]};
    }

    &.elevation-1 {
      box-shadow: ${theme.elevation[2]};
    }
  }

  &.alternative {
    background-color: #d14210;
    color: #ffffff;
  }

  &.travel {
    padding: 0.5em 2em;
    background-color: #008072;
    color: #ffffff;
  }
  
  &.square {
    padding: 0.5em 2em;
    background-color: ${theme.colors.backgroundDark};
    color: #ffffff;
    border-radius: 0;
  }

  &.has-border {
    border: 2px solid currentColor;

    &.active {
      border: 2px solid ${theme.colors.primary};
    }
  }

  &.colorInherit {
    color: inherit;
  }

  &.elevation-0 {
    box-shadow: ${theme.elevation[0]};
  }

  &.elevation-1 {
    box-shadow: ${theme.elevation[2]};
  }

  &.wide {
    padding: 0.75em 4em;
  }

  &:hover {
    border-color: ${theme.colors.primary};

    &.active {
      background-color: #ffffff;
      color: #d14210;
    }

    &.elevation-0 {
      box-shadow: ${theme.elevation[2]};
    }

    &.elevation-1 {
      box-shadow: ${theme.elevation[2]};
    }

    &.alternative {
      background-color: #ffffff;
      color: #d14210;
    }

    &.travel {
      background-color: #ffffff;
      color: #008072;
      border-color: #008072;
    }

    &.square {
      background-color: #ffffff;
      color: ${theme.colors.backgroundDark};
      border-color: ${theme.colors.backgroundDark};
    }
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      background-color: ${theme.colors.buttonBg};
    }
  }

  ${breakpoint.greaterThan('md')`
    display: inline-flex;
    font-size: 1em;
  `}
  ${breakpoint.greaterThan('lg')`
    font-size: 1.15rem;
  `}

  svg {
    font-size: 1em;
  }

  .icon--before {
    margin-right: 0.5em;
  }

  .icon--after {
    margin-left: 0.5em;
  }
`;

const styledGhostButton = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 0.8em 2em;
  line-height: 1;
  cursor: pointer;
  transition: 50ms ease-out;
  color: #000000;
  background-color: transparent;
  &.active {
    color: #ffffff;
    background-color: #707070;
  }
  .icon--before {
    margin-right: 0.5em;
  }
  .icon--after {
    margin-left: 0.5em;
  }
  &:hover {
    border-color: #707070;
    background-color: #707070;
    color: #fff;
  }
  ${breakpoint.greaterThan('md')`
    font-size: 1em;
    padding: 0.75em 1.75em;
  `};
`;
interface ButtonProp {
  ghost?: boolean;
}
export const StyledAnchor = styled.a<ButtonProp>`
  ${({ ghost }) => (ghost ? styledGhostButton : StyledDefaultButton)}
`;
export const StyledButton = styled.button<ButtonProp>`
  ${({ ghost }) => (ghost ? styledGhostButton : StyledDefaultButton)}
`;
