import React, { ReactElement } from 'react';
import StyledProjectInfoPanel, {
  StyledDownloadButton,
  StyledMoreButton,
} from './styles';
import { ProjectProps } from '../../../types';

type ClearingProjectsInfoPanelProps = {
  project: ProjectProps;
  closePanel?(event: React.MouseEvent<HTMLButtonElement>): void;
};

const ClearingProjectsInfoPanel = ({
  project,
  closePanel,
}: ClearingProjectsInfoPanelProps): ReactElement => {
  return (
    <StyledProjectInfoPanel phaseColor={project.color}>
      <div className="project-panel__top-section">
        <button className="close-button" type="button" onClick={closePanel}>
          <span className="visually-hidden">Close project info panel</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 330 330"
            height="25"
            width="25"
          >
            <path d="M165 0C74 0 0 74 0 165s74 165 165 165 165-74 165-165S256 0 165 0zm0 300c-74.4 0-135-60.6-135-135S90.6 30 165 30s135 60.6 135 135-60.6 135-135 135z" />
            <path d="M239.2 90.8a15 15 0 00-21.2 0l-53 53-53-53A15 15 0 1090.8 112l53 53-53 53a15 15 0 0021.2 21.2l53-53 53 53a15 15 0 0021.2 0 15 15 0 000-21.2l-53-53 53-53a15 15 0 000-21.2z" />
          </svg>
        </button>
        <h3 className="project-title">
          {project.projectType !== 'actual' && (
            <span className="phase-indicator" />
          )}
          {project.name}
        </h3>
        {project.projectType !== 'revegetation' &&
          project.phase &&
          project.phaseText && (
            <p className="project-phase">{project.phaseText}</p>
          )}
        {project.projectType === 'actual' && project.description && (
          <p className="project-description">{project.description}</p>
        )}
        {project.projectType === 'proposed' && project.link && (
          <StyledMoreButton href={project.link}>Find out more</StyledMoreButton>
        )}
      </div>
      <div className="project-panel__bottom-section">
        {project.projectType === 'actual' && (
          <>
            {project.referenceNumber && (
              <>
                <p className="project-panel__detail-label">Reference number:</p>
                <p className="project-panel__detail-data">
                  {project.referenceNumber}
                </p>
              </>
            )}
            {project.roadName && (
              <>
                <p className="project-panel__detail-label">Road name:</p>
                <p className="project-panel__detail-data">{project.roadName}</p>
              </>
            )}
            {project.localGov && (
              <>
                <p className="project-panel__detail-label">Local Government:</p>
                <p className="project-panel__detail-data">{project.localGov}</p>
              </>
            )}
            {project.region && (
              <>
                <p className="project-panel__detail-label">Region:</p>
                <p className="project-panel__detail-data">{project.region}</p>
              </>
            )}
            {project.cutOffDate && (
              <>
                <p className="project-panel__detail-label">
                  Submission cut off date:
                </p>
                <p className="project-panel__detail-data">
                  {project.cutOffDate}
                </p>
              </>
            )}
            {project.closedDate && (
              <>
                <p className="project-panel__detail-label">
                  Submission close date:
                </p>
                <p className="project-panel__detail-data">
                  {project.closedDate}
                </p>
              </>
            )}
            {project.numberOfSubmissions > 0 && (
              <>
                <p className="project-panel__detail-label">
                  Number of submissions:
                </p>
                <p className="project-panel__detail-data">
                  {project.numberOfSubmissions}
                </p>
              </>
            )}
            {project.downloadLink && (
              <StyledDownloadButton
                icon="file"
                href={project.downloadLink}
                target="_blank"
                ghost={true}
              >
                Download Final Report
              </StyledDownloadButton>
            )}
          </>
        )}

        {project.projectType === 'proposed' && (
          <>
            {project.clearingLocation && (
              <>
                <p className="project-panel__detail-label">
                  Central location of clearing:
                </p>
                <p
                  className="project-panel__detail-data"
                  dangerouslySetInnerHTML={{ __html: project.clearingLocation }}
                />
              </>
            )}
            {project.roadName && (
              <>
                <p className="project-panel__detail-label">Road name:</p>
                <p className="project-panel__detail-data">{project.roadName}</p>
              </>
            )}
            {project.localGov && (
              <>
                <p className="project-panel__detail-label">Local Government:</p>
                <p className="project-panel__detail-data">{project.localGov}</p>
              </>
            )}
            {project.region && (
              <>
                <p className="project-panel__detail-label">Region:</p>
                <p className="project-panel__detail-data">{project.region}</p>
              </>
            )}
            {project.clearingSize && (
              <>
                <p className="project-panel__detail-label">Size of clearing:</p>
                <p className="project-panel__detail-data">
                  {project.clearingSize}
                </p>
              </>
            )}
            {project.clearingStartDate && (
              <>
                <p className="project-panel__detail-label">
                  Clearing start date:
                </p>
                <p className="project-panel__detail-data">
                  {project.clearingStartDate}
                </p>
              </>
            )}
            {project.clearingEndDate && (
              <>
                <p className="project-panel__detail-label">
                  Clearing end date:
                </p>
                <p className="project-panel__detail-data">
                  {project.clearingEndDate}
                </p>
              </>
            )}
            {project.cutOffDate && (
              <>
                <p className="project-panel__detail-label">
                  Submission close date:
                </p>
                <p className="project-panel__detail-data">
                  {project.cutOffDate}
                </p>
              </>
            )}
            {project.downloadLink && (
              <StyledDownloadButton
                icon="file"
                href={project.downloadLink}
                target="_blank"
              >
                Download Final Report
              </StyledDownloadButton>
            )}
          </>
        )}

        {project.projectType === 'revegetation' && (
          <>
            {project.referenceNumber && (
              <>
                <p className="project-panel__detail-label">Reference number:</p>
                <p className="project-panel__detail-data">
                  {project.referenceNumber}
                </p>
              </>
            )}
            {project.area && (
              <>
                <p className="project-panel__detail-label">Area (ha):</p>
                <p className="project-panel__detail-data">{project.area}</p>
              </>
            )}
            {project.year && (
              <>
                <p className="project-panel__detail-label">
                  Year(s) Undertaken:
                </p>
                <p className="project-panel__detail-data">{project.year}</p>
              </>
            )}
            {project.phaseText && (
              <>
                <p className="project-panel__detail-label">Method:</p>
                <p className="project-panel__detail-data">
                  {project.phaseText}
                </p>
              </>
            )}
            {project.region && (
              <>
                <p className="project-panel__detail-label">Region:</p>
                <p className="project-panel__detail-data">{project.region}</p>
              </>
            )}
            {project.roadName && (
              <>
                <p className="project-panel__detail-label">Road name:</p>
                <p className="project-panel__detail-data">{project.roadName}</p>
              </>
            )}
            {project.roadNumber && (
              <>
                <p className="project-panel__detail-label">Road number:</p>
                <p className="project-panel__detail-data">
                  {project.roadNumber}
                </p>
              </>
            )}
            {project.clearingLocation && (
              <>
                <p className="project-panel__detail-label">Location:</p>
                <p
                  className="project-panel__detail-data"
                  dangerouslySetInnerHTML={{ __html: project.clearingLocation }}
                />
              </>
            )}
            {project.lastUpdated && (
              <>
                <p className="project-panel__detail-label">Last updated:</p>
                <p className="project-panel__detail-data">
                  {project.lastUpdated}
                </p>
              </>
            )}
            {project.downloadLink && (
              <StyledDownloadButton
                icon="file"
                href={project.downloadLink}
                target="_blank"
              >
                Download Report
              </StyledDownloadButton>
            )}
          </>
        )}
      </div>
    </StyledProjectInfoPanel>
  );
};

export default ClearingProjectsInfoPanel;
