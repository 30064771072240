import React, { ReactElement, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import { StyledIconWrapper, StyledSearchForm } from './styles';

export interface SearchFormProps {
  label?: string;
  minimal?: boolean;
  className?: string;
  initialValue?: string;
  onSubmit?: (value) => void;
  action?: string;
  placeholder?: string;
  searchIconBefore?: boolean;
  searchIconClickable?: boolean;
  theme?: 'light' | 'dark';
  searchInputWidth?: string;
  typeAheadSearch?: (value) => void;
  isHeader?: boolean;
  searchId?: string;
}

const SearchFormWidget = ({
  className,
  initialValue,
  onSubmit,
  action = '',
  placeholder = 'Search Main Roads',
  searchIconBefore = false,
  searchIconClickable = true,
  theme = 'light',
  searchInputWidth = '180px',
  typeAheadSearch,
  searchId = 'search',
}: SearchFormProps): ReactElement => {
  const [active, setActive] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (!!typeAheadSearch) {
      typeAheadSearch(inputValue);
    }
  }, [inputValue]);

  // Prevent form submit if we want to use the search form as a text input tool
  const onSubmitHandler = (ev: any) => {
    if (!!onSubmit || !searchIconClickable) {
      ev.preventDefault();
    }
    if (!!onSubmit) {
      onSubmit(inputValue);
    }
  };

  return (
    <StyledSearchForm
      action={!!typeAheadSearch ? '' : action} // The property will always be a string with slash ???
      className={`search-form theme-${theme} ${className ? className : ''}${
        active ? ' search-form--active' : ''
      }`}
      onSubmit={onSubmitHandler}
    >
      {!!searchIconBefore && (
        <div>
          {searchIconClickable ? (
            <button type="submit">
              <span className="visually-hidden">Submit</span>
              <FaSearch />
            </button>
          ) : (
            <StyledIconWrapper>
              <FaSearch />
            </StyledIconWrapper>
          )}
        </div>
      )}
      <div style={{ width: searchInputWidth }}>
        <label htmlFor="search" className="visually-hidden">
          Search Main Roads
        </label>
        <input
          type="text"
          id={searchId}
          name="q"
          value={inputValue}
          autoComplete="off"
          placeholder={placeholder}
          aria-label="Search Main Roads"
          onFocus={(): void => setActive(true)}
          onBlur={(): void => setActive(false)}
          onChange={(event): void => setInputValue(event.target.value)}
        />
      </div>

      {!searchIconBefore && (
        <div>
          {searchIconClickable ? (
            <button type="submit">
              <span className="visually-hidden">Submit</span>
              <FaSearch />
            </button>
          ) : (
            <StyledIconWrapper>
              <FaSearch />
            </StyledIconWrapper>
          )}
        </div>
      )}
    </StyledSearchForm>
  );
};

export default SearchFormWidget;
