import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkProps } from '../../../types';
import theme from '../../../lib/theme';
import '../../../lib/fontawesome';

const StyledNavSub = styled.div`
  h2 {
    font-size: 1.5rem;
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 0.8rem;
  }

  ul {
    padding-left: 0;
  }

  li {
    margin: 0 0 ${theme.spacing.xxs} 0;
    list-style: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.875rem 1rem;
    background-color: ${theme.colors.navSubBg};
    color: ${theme.colors.navSubText};
    font-size: 1rem;
    line-height: 1.2;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: ${theme.colors.linkHover};
      color: white;
      span {
        color: white;
      }
    }
  }
`;

export interface NavSubProps {
  className?: string;
  title: string;
  items: LinkProps[];
}

function NavSub({ className, title, items }: NavSubProps): ReactElement {
  return (
    <StyledNavSub className={className}>
      <h2>{title}</h2>
      <ul>
        {items.map(({ href, label, target = '_self' }, i) => (
          <li key={i}>
            <a href={href} target={target}>
              <span>{label}</span>
              <FontAwesomeIcon icon="chevron-right" />
            </a>
          </li>
        ))}
      </ul>
    </StyledNavSub>
  );
}

export default NavSub;
